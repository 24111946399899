<template>
  <div class="recent-events-widget-cont">
    <p class="theme-title mb-5">Recent Events</p>
    <div class="row equal">
      <div
        class="col-12 col-md-6 col-lg-3 item"
        v-for="(event, key) in $store.getters.latestEvents"
        :key="key"
        @click="viewEvent(event)"
      >
        <EventCard class="clickable" :event="event" layout="widget" />
      </div>
    </div>
    <br />
    <button
      class="link-btn-orange mt-5"
      @click="
        $router.push({
          name: 'campusEvents',
        })
      "
    >
      View all events
      <i class="icon-feather-arrow-right"></i>
    </button>
  </div>
</template>

<script>
import EventCard from "../campusLife/eventCard.vue";

export default {
  name: "RecentEventsWidget",
  components: { EventCard },

  methods: {
    viewEvent(e) {
      this.$router.push({ name: "viewEvent", params: { id: e.id } });
    },
  },
};
</script>

<style scoped>
.recent-events-widget-cont {
  padding: 50px;
}
.row {
  max-width: 1920px;
  margin: 0 auto;
}
</style>
