import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "Home",
    },
  },

  {
    path: "/admission",
    name: "admission",
    component: () =>
      import(/* webpackChunkName: "admission" */ "@/views/AdmissionView.vue"),
    meta: {
      title: "Admission",
    },
  },
  {
    path: "/academics",
    name: "academics",
    component: () =>
      import(/* webpackChunkName: "academics" */ "@/views/AcademicView.vue"),
    meta: {
      title: "Academics",
    },
  },
  {
    path: "/department/:thisDept",
    name: "viewDepartment",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "viewDepartment" */ "@/views/ViewDepartment.vue"
      ),
    meta: {
      title: "Department",
    },
  },
  {
    path: "/contact-us",
    name: "contactUs",
    component: () =>
      import(/* webpackChunkName: "contactUs" */ "@/views/ContactUsView.vue"),
    meta: {
      title: "Contact Us",
    },
  },
  {
    path: "/news",
    name: "news",
    component: () =>
      import(/* webpackChunkName: "news" */ "@/views/NewsView.vue"),
    meta: {
      title: "News",
    },
    children: [
      {
        path: "read/:id",
        name: "readNews",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "newsArticle" */ "@/components/news/newsArticle.vue"
          ),
        meta: {
          title: "News",
        },
      },
      {
        path: "gallery",
        name: "newsGallery",
        alias: "/news",
        component: () =>
          import(
            /* webpackChunkName: "newsGallery" */ "@/components/news/newsGallery.vue"
          ),
        meta: {
          title: "News",
        },
      },
    ],
  },
  {
    path: "/finance",
    name: "finance",
    component: () =>
      import(/* webpackChunkName: "finance" */ "@/views/FinanceView.vue"),
    meta: {
      title: "Finance",
    },
  },
  {
    path: "/campusLife",
    name: "campusLife",
    component: () =>
      import(/* webpackChunkName: "campusLife" */ "@/views/CampusLifeView.vue"),
    meta: {
      title: "Campus Life",
    },
  },
  {
    path: "/campusEvents",
    name: "campusEvents",
    component: () =>
      import(
        /* webpackChunkName: "campusEvents" */ "@/components/campusLife/events.vue"
      ),
    meta: {
      title: "Campus Life",
    },
  },
  {
    path: "/campus_event/:id",
    name: "viewEvent",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "campusEvents" */ "@/components/campusLife/viewEvent.vue"
      ),
    meta: {
      title: "Campus Life",
    },
  },

  {
    path: "/privacy-policy",
    name: "privacyPolicy",
    component: () =>
      import(/* webpackChunkName: "privacyPolicy" */ "@/views/PrivacyView.vue"),
    meta: {
      title: "Privacy Policy",
    },
  },
  {
    path: "*",
    name: "pageNotFound",
    component: () =>
      import(/* webpackChunkName: "pageNotFound" */ "@/views/pagenotfound.vue"),
    meta: {
      title: "404 page not found",
    },
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    meta: {
      title: "About",
    },
    children: [
      {
        path: "spac",
        name: "aboutSPAC",
        component: () =>
          import(
            /* webpackChunkName: "aboutSPAC" */ "../components/about/spac.vue"
          ),
        meta: {
          title: "About SPAC",
        },
      },
      {
        path: "administrator/:profileID",
        name: "adminProfile",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "adminProfile" */ "../components/about/profile.vue"
          ),
        meta: {
          title: "Admin",
        },
      },
    ],
  },

  {
    path: "/remittance_of_payment",
    name: "bankD",
    component: () =>
      import(/* webpackChunkName: "bankD" */ "@/views/BankDetails.vue"),
    meta: {
      title: "Remittance of Payment - Banks",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll 10px above the element #main
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        selector: "body",
        top: 0,
        behavior: "smooth",
      };
    }
  },
});

export default router;
