<template>
  <div class="news-card">
    <div class="img" :style="bgImg">
      <!-- <img :src="article.feature_image" /> -->
    </div>
    <div class="news-orange-bar"></div>
    <div class="info">
      <p class="title">{{ article.title }}</p>
      <p class="subtitle">{{ article.subtitle }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewsCard",
  props: ["article"],
  computed: {
    bgImg() {
      if (!this.article.feature_image) return null;
      return `background-image: url('${this.article.feature_image}')`;
    },
  },
};
</script>

<style scoped>
.news-orange-bar {
  margin: 0 auto;
  height: 10px;
  border-radius: 16px;
  width: 60%;
  margin-top: -5px;
  background: rgb(255, 211, 0);
  background: -moz-linear-gradient(
    149deg,
    rgba(255, 211, 0, 1) 0%,
    rgba(255, 120, 0, 1) 100%
  );
  background: -webkit-linear-gradient(
    149deg,
    rgba(255, 211, 0, 1) 0%,
    rgba(255, 120, 0, 1) 100%
  );
  background: linear-gradient(
    149deg,
    rgba(255, 211, 0, 1) 0%,
    rgba(255, 120, 0, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffd300",endColorstr="#ff7800",GradientType=1);
}
.img {
  width: 100%;
  padding-top: 56%;

  background-position: center;
  background-size: cover;
}
.news-card {
  border-radius: 16px;
  background: white;
  text-align: left;
  overflow: hidden;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  transition: 0.4s ease;
  height: 100%;
  margin-bottom: 1.5rem;
  font-family: "Avenir roman";
}
.news-card:hover {
  cursor: pointer;
  transform: scale(1.05);
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.3);
}
.title {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.5rem;
  font-family: "Lemon Milk";
  margin-bottom: 1rem;
}
.subtitle {
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.2rem;
  color: rgb(255 183 0);
  margin-bottom: 1rem;
}
.info {
  padding: 1rem;
}
</style>
