<template>
  <div class="main-nav-bar-outer-cont">
    <div class="main-nav-bar animated" id="main-nav-bar">
      <div class="inner-cont">
        <div class="logo-cont">
          <router-link
            class="mobile-left-nav-cont"
            v-if="$route.name !== 'home'"
            :to="{ name: 'home' }"
          >
            <i class="icon-feather-home"></i>
          </router-link>
          <img
            class="spac_logo_black"
            src="/img/SPAC_LOGO_blacknwhite.png"
            @click="$router.push({ name: 'home' })"
          />
          <img
            class="spac_logo_white"
            src="/img/spac-logo-white.png"
            @click="$router.push({ name: 'home' })"
          />
          <img
            class="spac_logo_fixed"
            src="/img/spac logo.png"
            @click="$router.push({ name: 'home' })"
          />
          <span
            class="mobile-right-nav-cont mobile-toggle"
            :class="showMobileMenu ? 'active' : ''"
            @click="toggleMobileMenu(true)"
          >
            <i class="icon-feather-menu"></i>
          </span>
        </div>
        <div class="left-nav-cont">
          <div class="upper">
            <nav-item
              :item="item"
              variant="link-1"
              v-for="(item, key) in topLeft"
              :key="key"
            />
          </div>
          <div class="lower">
            <nav-item
              :item="item"
              variant="link-2"
              v-for="(item, key) in bottomLeft"
              :key="key"
            />
          </div>
        </div>
        <div class="right-nav-cont">
          <div class="upper">
            <nav-item
              :item="item"
              variant="link-1"
              v-for="(item, key) in topRight"
              :key="key"
            />
          </div>
          <div class="lower">
            <nav-item
              :item="item"
              variant="link-2"
              v-for="(item, key) in bottomRight"
              :key="key"
            />
          </div>
        </div>
      </div>
      <div class="bar-bg">
        <div></div>
      </div>
    </div>
    <div class="mobile-nav hidden animated">
      <span class="mobile-nav-close" @click="toggleMobileMenu(false)">
        <i class="icon-feather-x"></i>
      </span>
      <div class="item" v-for="(item, key) in menuItems" :key="key">
        <nav-item :item="item" variant="link-2" />
      </div>
    </div>
  </div>
</template>

<script>
import NavItem from "./NavItem.vue";
export default {
  components: { NavItem },
  name: "MainNavBar",
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    // this.toggleNav();
    if (this.$route.name === "home") $(".main-nav-bar").addClass("route-home");
    else $(".main-nav-bar").removeClass("route-home");
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {
    fixed() {
      this.toggleNav();
    },
    "$route.name"() {
      this.showMobileMenu = false;
      if (this.$route.name === "home")
        $(".main-nav-bar").addClass("route-home");
      else $(".main-nav-bar").removeClass("route-home");
    },
    showMobileMenu() {
      this.lockToggle = true;
      if (this.showMobileMenu) {
        $("body").addClass("no-scroll");
        $(".mobile-nav").removeClass("hidden").addClass("fadeInRight");
        setTimeout(() => {
          this.lockToggle = false;
        }, 500);
      } else {
        $("body").removeClass("no-scroll");
        $(".mobile-nav").removeClass("fadeInRight").addClass("fadeOutRight");
        setTimeout(() => {
          $(".mobile-nav").addClass("hidden").removeClass("fadeOutRight");
          setTimeout(() => {
            this.lockToggle = false;
          }, 100);
        }, 1000);
      }
    },
  },
  computed: {
    showAltLogo() {
      return $(".main-nav-bar").hasClass("fixed");
    },
    topLeft() {
      return this.menuItems.filter((i) => i.placement === "top left");
    },
    bottomLeft() {
      return this.menuItems.filter((i) => i.placement === "bottom left");
    },
    topRight() {
      return this.menuItems.filter((i) => i.placement === "top right");
    },
    bottomRight() {
      return this.menuItems.filter((i) => i.placement === "bottom right");
    },
  },
  data() {
    return {
      compKey: Math.floor(Math.random() * (+999999 - +1)) + +1,
      fixed: false,
      menuItems: [
        {
          title: "AIS PORTAL",
          type: "external",
          link: "https://student.spac-ais.com/",
          addClass: "ais",
          placement: "top left",
        },
        {
          title: "admission",
          type: "internal",
          link: "admission",
          addClass: "",
          placement: "bottom left",
        },
        {
          title: "academics",
          type: "internal",
          link: "academics",
          addClass: "",
          placement: "bottom left",
          sub_menu: [
            { title: "Theology", id: "theology", link: "viewDepartment" },
            { title: "teacher education", id: "ted", link: "viewDepartment" },
            { title: "accountancy", id: "bsa", link: "viewDepartment" },
            {
              title: "Business Administration",
              id: "bsba",
              link: "viewDepartment",
            },
            { title: "computer science", id: "bscs", link: "viewDepartment" },
            {
              title: "Office Administration",
              id: "bsoa",
              link: "viewDepartment",
            },
            {
              title: "basic education",
              id: "basic_ed",
              link: "viewDepartment",
            },
          ],
        },
        {
          title: "news",
          type: "internal",
          link: "newsGallery",
          addClass: "",
          placement: "top right",
        },
        {
          title: "finance",
          type: "internal",
          link: "finance",
          addClass: "",
          placement: "bottom right",
        },
        {
          title: "contact us",
          type: "internal",
          link: "contactUs",
          addClass: "",
          placement: "bottom right",
        },
        {
          title: "campus life",
          type: "internal",
          link: "campusLife",
          addClass: "",
          placement: "top left",
        },

        {
          title: "about spac",
          type: "internal",
          link: "aboutSPAC",
          addClass: "",
          placement: "top right",
        },
      ],
      showMobileMenu: false,
      lockToggle: false,
    };
  },
  methods: {
    handleScroll() {
      if ($(".main-nav-bar").hasClass("fixed-permanent")) {
        return false;
      }
      if (this.showMobileMenu) return false;
      // Any code to be executed when the window is scrolled
      let navBar = document.getElementById("main-nav-bar");
      if (window.scrollY > navBar.offsetTop + 60) {
        this.fixed = true;
      } else {
        this.fixed = false;
      }
    },
    toggleNav() {
      if (this.fixed) {
        $(".main-nav-bar").addClass("fixed");
      } else {
        $(".main-nav-bar").removeClass("fixed");
      }
    },
    toggleMobileMenu(set) {
      if (this.lockToggle) return false;
      this.showMobileMenu = set;
    },
  },
};
</script>

<style SCOPED>
.bar-bg {
  width: 100%;
  height: 30px;
  background: #063077;
  background: radial-gradient(circle, #063077 0, #000b41 100%);
  display: none;
  border-bottom: 2px solid var(--orange);
  transition: 0.4s ease;
}
a.mobile-left-nav-cont {
  text-decoration: none;
}
.bar-bg:before {
  content: " ";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  border: 29px solid rgba(255, 255, 255, 0.8);
}
.mobile-toggle {
  z-index: 999;
}
.fixed .bar-bg {
  display: block;
}
.hidden {
  opacity: 0;
}
.mobile-nav-close {
  color: black;
  position: fixed;
  top: 23px;
  right: 32px;
  font-size: 2rem;
}
.main-nav-bar {
  position: fixed;
  left: 0;
  right: 0;
  /* padding-top: 100px; */
  width: 100%;
  z-index: 997;
  height: 20vh;
  color: #fff;
  margin: 0 auto;
  font-size: 1.2rem;
  transition: 0.4s ease;
  background: linear-gradient(180deg, rgb(0 0 0 / 20%), transparent);
}
.lightTopBG .main-nav-bar {
  background: none;
}
.inner-cont {
  transition: 0.4s ease;
  transform: translateY(5vh);
}
.route-home .inner-cont {
  transform: translateY(100px) !important;
}
.route-home.fixed .inner-cont {
  transform: unset !important;
}
.route-home.main-nav-bar {
  background: linear-gradient(180deg, rgb(0 0 0 / 55%), transparent);
  height: 300px;
}
.fixed .inner-cont {
  transform: unset;
}
.main-nav-bar.fixed {
  top: 0;
  padding-top: 0 !important;
  height: 58px;
  box-shadow: 0px 1px 22px rgba(0, 0, 0, 0.15);
}

.left-nav-cont {
  text-align: right;
  right: 62%;
}
.left-nav-cont,
.right-nav-cont {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 30px;
  height: 60px;
  justify-content: space-evenly;
  /* transform: translateY(-86px); */
}
.logo-cont {
  width: 100%;
  display: block;
  position: relative;
  height: 0px;
  z-index: 2;
}
.logo-cont img {
  width: 120px;
  margin: 0 auto;
  cursor: pointer;
}
.route-home .logo-cont img {
  width: 120px;
}
.fixed .logo-cont img {
  width: 60px;
}

.fixed .logo-cont {
  top: 2px;
}
.lightTopBG .logo-cont img.spac_logo_black,
.fixed .logo-cont img.spac_logo_fixed {
  display: unset;
}
.logo-cont img.spac_logo_black,
.fixed .logo-cont img.spac_logo_black,
.fixed .logo-cont img.spac_logo_white,
.lightTopBG .logo-cont img.spac_logo_white,
.logo-cont img.spac_logo_fixed {
  display: none;
}

.right-nav-cont {
  left: 62%;
  text-align: left;
}
.fixed .right-nav-cont {
  left: 56%;
  top: 0;
}
.fixed .left-nav-cont {
  right: 56%;
  top: 0;
}
.right-nav-cont .upper,
.left-nav-cont .upper,
.right-nav-cont .lower,
.left-nav-cont .lower {
  display: flex;
}
.left-nav-cont .upper,
.left-nav-cont .lower {
  justify-content: flex-end;
}
.right-nav-cont .upper a,
.right-nav-cont .lower a {
  margin-right: 40px;
}
.left-nav-cont .upper a,
.left-nav-cont .lower a {
  margin-left: 40px;
}

.mobile-nav {
  position: fixed;
  width: 100%;
  height: 100%;
  padding-top: 120px;
  top: 0;
  background: white;
  display: none;
  z-index: 998;
}
.mobile-nav .item .link-1,
.mobile-nav .item .link-2 {
  margin: 20px 40px;
  text-align: left;
  font-weight: 500;
  color: grey;
  font-size: 1.5rem;
  border-bottom: 1px solid rgb(221, 221, 221);
}
.mobile-left-nav-cont,
.mobile-right-nav-cont {
  position: fixed !important;
  color: white;
  margin: 12px 40px;
  top: 12px;
  font-size: 1.4rem;
  display: none;
  transition: 0.4s ease;
}
.fixed .mobile-left-nav-cont,
.fixed .mobile-right-nav-cont {
  top: 0;
  color: rgb(0, 0, 95) !important;
}
.lightTopBG .mobile-left-nav-cont,
.lightTopBG .mobile-right-nav-cont {
  color: rgb(42, 42, 42);
}
.mobile-left-nav-cont {
  left: 0;
}
.mobile-right-nav-cont {
  right: 0;
}
.mobile-right-nav-cont.active {
  color: var(--orange) !important;
}
.mobile-nav.hidden {
  display: none;
}
@media (max-width: 769px) {
  .right-nav-cont,
  .left-nav-cont {
    display: none;
  }
  .mobile-left-nav-cont,
  .mobile-right-nav-cont {
    display: unset;
  }
  .mobile-nav {
    display: block;
  }
  .bar-bg {
    height: 55px;
  }
  .main-nav-bar.fixed {
    background: none;
    height: 55px;
  }
  .fixed .logo-cont {
    top: 10px;
  }
  /* .main-nav-bar.route-home {
    padding-top: 100px;
  } */
  .main-nav-bar {
    padding-top: 10px;
    height: 80px;
  }
  .main-nav-bar.route-home.fixed {
    height: 55px;
  }

  .logo-cont img {
    width: 60px;
  }
  .inner-cont {
    transform: unset;
  }
  .bar-bg:before {
    display: none;
  }
  .bar-bg {
    background: #ffffff;
    background: radial-gradient(circle, #ffffff 0, #ececec 100%);
  }
}
</style>
