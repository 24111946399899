<template>
  <div class="footer-cont">
    <div class="footer">
      <img class="spac-logo" src="/img/spac-logo-orange.png" />
      <p class="spac-t">
        South Philippine <br />
        Adventist College
      </p>
      <div class="white-bar-h"></div>
      <p class="address">
        CAMANCHILES, MATANAO <br />
        DAVAO DEL SUR
      </p>
      <p class="contact-t mt-5">CONTACTS</p>
      <div class="contacts-cont">
        <div>
          <label for="">EMAIL</label>
          <p class="value">info@spac.edu.ph</p>
        </div>
        <div>
          <label for="">enrollment team</label>
          <p class="value">0930 712 7276 <br /></p>
        </div>
        <div>
          <label for="">registrar</label>
          <p class="value">0928 589 4421</p>
        </div>
        <div>
          <label for="">finance</label>
          <p class="value">
            0976 317 1129 <br />
            (082) 284 6283 (landline)
          </p>
        </div>
      </div>
    </div>
    <div class="copy-right">
      <div class="row">
        <div class="col-md-6 col-12">
          <a
            href="https://www.facebook.com/SPAConline"
            target="_blank"
            class="social-icons"
          >
            <img src="/img/facebook.jpg" alt="facebook" />
          </a>
          <a
            href="https://www.youtube.com/@southphilippineadventistco7498"
            target="_blank"
            class="social-icons"
          >
            <img src="/img/youtube.jpg" alt="youtube" />
          </a>
        </div>
        <div
          class="col-md-6 col-12 d-flex align-items-center justify-content-end"
        >
          Copyright © 2024 South Philippine Adventist College. All rights
          reserved.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterPanel",
};
</script>

<style scoped>
.footer-cont {
  position: relative;
  padding-top: 20px;
  padding: 0 1%;
  width: 100%;
}
.footer {
  border-radius: 16px;
  position: inherit;
  margin-top: 45px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgb(6, 48, 119);
  background: -moz-radial-gradient(
    circle,
    rgba(6, 48, 119, 1) 0%,
    rgba(0, 11, 65, 1) 100%
  );
  background: -webkit-radial-gradient(
    circle,
    rgba(6, 48, 119, 1) 0%,
    rgba(0, 11, 65, 1) 100%
  );
  background: radial-gradient(
    circle,
    rgba(6, 48, 119, 1) 0%,
    rgba(0, 11, 65, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#063077",endColorstr="#000b41",GradientType=1);
}
img.spac-logo {
  width: 100px;
  margin-top: 40px;
  margin-bottom: 20px;
}
.spac-t {
  font-family: "Trajan Pro";
  font-size: 2.2rem;
  background-color: rgb(255, 208, 0);
  background-image: -webkit-linear-gradient(
    280deg,
    rgb(255, 213, 0) 0%,
    rgb(255, 98, 0) 100%
  );
  background-color: #f3ec78;
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  text-shadow: 0px 0px #00000000;
}
.address {
  margin-top: 20px;
  color: #fff;
  font-family: "LEMON MILK", "Helvetica", san-serif;
  font-weight: 400;
}
.contact-t {
  color: rgb(255, 208, 0);
  font-family: "LEMON MILK", "Helvetica", san-serif;
  font-weight: 700;
  font-size: 1rem;
  margin-top: 70px;
}
a img {
  height: 30px;
  margin: 0 10px;
}
.contacts-cont {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-bottom: 40px;
  flex-wrap: wrap;
}
.contacts-cont div {
  min-width: 200px;
}
label {
  color: rgb(255, 208, 0);
  font-family: "LEMON MILK", "Helvetica", san-serif;
  font-weight: 400;
  font-size: 0.7rem;
}
.copy-right {
  padding: 10px;
  font-family: "LEMON MILK", "Helvetica", san-serif;
  font-weight: 400;
  font-size: 0.7rem;
  text-align: left;
}
.copy-right:first-child {
  text-align: left;
}
.value {
  color: white;
  font-family: "LEMON MILK", "Helvetica", san-serif;
  font-weight: 700;
  font-size: 1.2rem;
}
.social-icons {
  font-size: 2rem;
  color: rgb(255, 179, 0);
  margin-right: 10px;
  background: -webkit-linear-gradient(
    180deg,
    rgba(255, 180, 0, 1) 0%,
    rgba(255, 138, 0, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 0px #00000000;
}
@media only screen and (min-width: 1000px) {
  .footer-cont {
    padding: 0 3.57%;
  }
}
@media (max-width: 769px) {
  .footer-cont {
    padding: 0px;
  }
  .footer {
    padding: 0 3.57%;
    border-radius: 0px;
  }
  .copy-right {
    text-align: center !important;
  }
}
</style>
