<template>
  <div class="panel1-cont">
    <div class="title-1">
      <p>
        South Philippine <br />
        Adventist College
      </p>
      <div class="white-bar-h"></div>
      <div class="sub-1">The School That Nurtures Holistic Excellence</div>
      <!-- <a href="https://enrol.spac-ais.com/" target="_blank" class="enroll-btn">
        Enrol Now
      </a> -->
      <!-- <img class="aniv-img" src="img/spac_74.png" /> -->
    </div>
    <div class="home-vd-bg-cont">
      <video-background src="./video/web_hero.mp4" class="home-vd-bg">
      </video-background>
    </div>
  </div>
</template>

<script>
import VideoBackground from "vue-responsive-video-background-player";

export default {
  name: "HomePanel1",
  components: {
    VideoBackground,
  },
};
</script>

<style scoped>
.home-vd-bg-cont {
  background: black;
}
.home-vd-bg {
  opacity: 0.5;
  height: 100vh;
  min-height: 1000px;
}
.title-1 {
  font-family: "Trajan Pro";
  font-size: clamp(2rem, 7vw + 1rem, 2.2rem);
  color: white;
  position: absolute;
  width: 100%;
  top: 250px;
  text-align: center;
  z-index: 1;
}
.title-1 p {
  margin: 0 auto;
  margin-bottom: 40px;
}

.sub-1 {
  font-family: "Autography";
  font-size: clamp(1.5rem, 7vw + 1rem, 2rem);
  color: white;
  margin-top: 50px;
  margin-bottom: 50px;
}
a.enroll-btn {
  display: inline-block;
  margin-top: 90px;
  padding: 15px 40px;
  cursor: pointer;
  transition: 0.4s ease;
  font-family: "LEMON MILK", "Helvetica", san-serif;
  font-weight: 500;
  font-size: 1.5rem;
  letter-spacing: 0.6rem;
  color: white;
  border: none;
  text-decoration: none;
  border-radius: 10px;
  background: rgb(255, 211, 0);
  background: -moz-linear-gradient(
    149deg,
    rgba(255, 211, 0, 1) 0%,
    rgba(255, 120, 0, 1) 100%
  );
  background: -webkit-linear-gradient(
    149deg,
    rgba(255, 211, 0, 1) 0%,
    rgba(255, 120, 0, 1) 100%
  );
  background: linear-gradient(
    149deg,
    rgba(255, 211, 0, 1) 0%,
    rgba(255, 120, 0, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffd300",endColorstr="#ff7800",GradientType=1);
}
a.enroll-btn:hover {
  transform: scale(1.1);
}
.aniv-img {
  display: block;
  width: 100%;
  max-width: 400px;
  margin: 2% auto;
}
@media (max-width: 769px) {
  .aniv-img {
    width: 80%;
    margin: 30px auto;
  }
}
@media (max-height: 900px) {
  .title-1 p {
    margin-bottom: 20px;
  }
  .sub-1 {
    margin: 30px 20px 20px 20px;
  }
  a.enroll-btn {
    margin-top: 30px;
  }
}
</style>
