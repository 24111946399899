<template>
  <div id="app">
    <nav-bar />
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <recent-news v-if="showRecentNewsWidget" />
    <recent-events v-if="showRecentEventsWidget" />
    <Footer v-if="showFooter" />
  </div>
</template>
<script>
import Footer from "@/components/footer.vue";
import NavBar from "@/components/NavBar.vue";
import RecentEvents from "./components/widgets/recentEvents.vue";
import RecentNews from "./components/widgets/recentNews.vue";
export default {
  components: {
    NavBar,
    Footer,
    RecentEvents,
    RecentNews,
  },
  mounted() {},
  computed: {
    showFooter() {
      switch (this.$route.name) {
        case "AlumniHomeComing":
          return false;
      }
      return true;
    },
    showRecentNewsWidget() {
      switch (this.$route.name) {
        case "news":
        case "newsGallery":
          return false;
      }
      return true;
    },
    showRecentEventsWidget() {
      switch (this.$route.name) {
        case "campusLife":
        case "campusEvents":
        case "AlumniHomeComing":
          return false;
      }
      return true;
    },
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
body.no-scroll {
  overflow: hidden;
}
body {
  margin: 0px;
  font-family: "Avenir roman";
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.modal-header .close {
  border: none;
  font-size: 2.5rem;
  line-height: 0.5;
  background: none;
  font-weight: 700;
}
.white-bar-h {
  background: white;
  height: 5px;
  border-radius: 16px;
  width: 150px;
  margin: 0 auto;
}

.orange-bar-v {
  width: 5px;
  border-radius: 16px;
  height: 150px;
  background: rgb(255, 211, 0);
  background: -moz-linear-gradient(
    149deg,
    rgba(255, 211, 0, 1) 0%,
    rgba(255, 120, 0, 1) 100%
  );
  background: -webkit-linear-gradient(
    149deg,
    rgba(255, 211, 0, 1) 0%,
    rgba(255, 120, 0, 1) 100%
  );
  background: linear-gradient(
    149deg,
    rgba(255, 211, 0, 1) 0%,
    rgba(255, 120, 0, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffd300",endColorstr="#ff7800",GradientType=1);
}
.orange-bar-h {
  margin: 30px 0px;

  height: 5px;
  border-radius: 16px;
  width: 150px;
  background: rgb(255, 211, 0);
  background: -moz-linear-gradient(
    149deg,
    rgba(255, 211, 0, 1) 0%,
    rgba(255, 120, 0, 1) 100%
  );
  background: -webkit-linear-gradient(
    149deg,
    rgba(255, 211, 0, 1) 0%,
    rgba(255, 120, 0, 1) 100%
  );
  background: linear-gradient(
    149deg,
    rgba(255, 211, 0, 1) 0%,
    rgba(255, 120, 0, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffd300",endColorstr="#ff7800",GradientType=1);
}
.fade {
  animation-duration: 0.2s;
}
.Masthead {
  z-index: 0 !important;
}
:root {
  --animate-duration: 200ms;
  --animate-delay: 0.9s;
  --orange: rgb(255, 208, 0);
}
.link-btn-orange {
  font-family: "LEMON MILK", "Helvetica", san-serif;
  font-weight: 500;
  color: rgb(255, 208, 0);
  transition: 0.4s ease;
  border: none;
  background: none;
}
.link-btn-orange:hover {
  color: rgb(255, 170, 0);
  transform: scale(1.1);
}
.link-btn-orange:hover a {
  box-shadow: 5px 5px 3px rgba(0, 0, 0, 0.7);
}
#app {
  overflow: hidden;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.theme-title {
  font-family: "LEMON MILK", "Helvetica", san-serif;
  font-weight: 700;
  font-size: 2rem;
  color: var(--orange);
}
.gradient-blue {
  background: rgb(0, 71, 162);
  background: -moz-linear-gradient(
    149deg,
    rgba(0, 71, 162, 1) 0%,
    rgba(0, 6, 41, 1) 100%
  );
  background: -webkit-linear-gradient(
    149deg,
    rgba(0, 71, 162, 1) 0%,
    rgba(0, 6, 41, 1) 100%
  );
  background: linear-gradient(
    149deg,
    rgba(0, 71, 162, 1) 0%,
    rgba(0, 6, 41, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0047a2",endColorstr="#000629",GradientType=1);
}
.gradient-orange {
  background: -moz-linear-gradient(
    149deg,
    rgba(255, 211, 0, 1) 0%,
    rgba(255, 120, 0, 1) 100%
  );
  background: -webkit-linear-gradient(
    149deg,
    rgba(255, 211, 0, 1) 0%,
    rgba(255, 120, 0, 1) 100%
  );
  background: linear-gradient(
    149deg,
    rgba(255, 211, 0, 1) 0%,
    rgba(255, 120, 0, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffd300",endColorstr="#ff7800",GradientType=1);
}

.gradient-orange-text {
  background: -moz-linear-gradient(
    149deg,
    rgba(255, 211, 0, 1) 0%,
    rgba(255, 120, 0, 1) 100%
  );
  background: -webkit-linear-gradient(
    149deg,
    rgba(255, 211, 0, 1) 0%,
    rgba(255, 120, 0, 1) 100%
  );
  background: linear-gradient(
    149deg,
    rgba(255, 211, 0, 1) 0%,
    rgba(255, 120, 0, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffd300",endColorstr="#ff7800",GradientType=1);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 0px #00000000;
}

.row.equal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}
.row.equal > [class*="col-"] {
  display: flex;
  flex-direction: column;
}

.row.equal .element-box {
  height: 100%;
}
#nprogress .spinner {
  display: none !important;
}
.link-btn-orange {
  margin: 4%;
  font-size: 1.5rem;
}
.text-orange {
  color: var(--orange) !important;
}

.facebook-iframe {
  border-radius: 16px;
}
.page-loader {
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
iframe.facebook-iframe .uiGrid,
._2q20,
.clear-fix {
  display: none !important;
}

.pagination-nav .page-link.active,
.active > .page-link {
  background: var(--orange) !important;
  border-color: var(--orange) !important;
}
.pagination-nav .page-link {
  color: rgb(79, 79, 79) !important;
}

.pagination-nav .disabled .page-link {
  color: rgb(180, 180, 180) !important;
}
.text-bold {
  font-weight: 600;
}

.clickable .badge,
.clickable a,
.clickable b,
.clickable canvas,
.clickable div,
.clickable div::before,
.clickable g,
.clickable h1,
.clickable h2,
.clickable h3,
.clickable h4,
.clickable h5,
.clickable h6,
.clickable i,
.clickable i::before,
.clickable img,
img.clickable,
.clickable input .clickable span span,
.clickable label,
.clickable path,
.clickable small,
.clickable span,
.clickable strong,
.clickable svg,
.clickable td,
.clickable tr,
.clickable th,
.has-sub-menu-in-top .icon-w,
.has-sub-menu-in-top .icon-w ::before,
.has-sub-menu-in-top a {
  cursor: pointer;
}
@media (max-width: 769px) {
  .orange-bar-v {
    height: 5px;
    width: 150px;
    margin-top: 50px;
  }
  :not(input):not(textarea),
  :not(input):not(textarea)::after,
  :not(input):not(textarea)::before {
    -webkit-user-select: none;
    user-select: none;
    cursor: default;
  }
}

.news-cont content img {
  width: 100%;
  border-radius: 16px;
  margin-bottom: 1rem;
}

.standard-cont {
  font-family: "Avenir roman";
  max-width: 1800px;
  margin: 0 auto;
  padding: 5rem;
}
@media (max-width: 769px) {
  .standard-cont {
    padding: 3rem;
  }
}
</style>
