<template>
  <div class="recent-events-widget-cont">
    <p class="theme-title mb-5">Latest News</p>
    <div class="row equal">
      <div
        class="col-12 col-md-6 col-lg-3 item"
        v-for="(article, key) in $store.getters.latestNews"
        :key="key"
        @click="viewNews(article)"
      >
        <news-card :article="article" />
      </div>
    </div>
    <br />
    <button
      class="link-btn-orange mt-5"
      @click="
        $router.push({
          name: 'newsGallery',
        })
      "
    >
      More News
      <i class="icon-feather-arrow-right"></i>
    </button>
  </div>
</template>

<script>
import NewsCard from "../news/newsCard.vue";

export default {
  name: "RecentEventsWidget",
  components: { NewsCard },

  methods: {
    viewNews(e) {
      this.$router.push({ name: "readNews", params: { id: e.title } });
    },
  },
};
</script>

<style scoped>
.recent-events-widget-cont {
  padding: 50px;
  background: #f8f9f9;
}
.row {
  max-width: 1920px;
  margin: 0 auto;
}
</style>
