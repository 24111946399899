import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    events: [
      {
        id: 12,
        title: "South Philipine Adventist College 73rd Founding Anniversary",
        date: "Feb 24 2023",
        source: "facebook",
        postedBy: {
          img: "/img/spac_media_productions_logo.png",
          name: "SPAC Media Productions",
          link: "https://www.facebook.com/SPAConline",
        },
        photographers: ["SPAC Media", "SPAC Media Productions"],
        iframeVideo:
          "https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fweb.facebook.com%2FSPAConline%2Fvideos%2F755441129509907%2F&show_text=false&width=560&t=0",
      },
      {
        id: 1,
        title: "Fun Run",
        subtitle:
          "73rd Founding Anniversary of South Philippine Adventist College",
        description: "",
        date: "Feb 24 2023",
        photos: [
          "332931008_563750645699794_5963871151921322923_n.jpg",
          "332946589_226189663145709_7382015704391216934_n.jpg",
          "333042417_1856229598097607_3006167315463026888_n.jpg",
          "333113054_1256410154956010_693515571002597543_n.jpg",
          "333202904_598867191715878_1646863893689488376_n.jpg",
          "333229563_987613999291330_7009290737030150103_n.jpg",
          "333237006_599972804865652_6952651828155036959_n.jpg",
          "333237660_932141468143865_2209813052332457497_n.jpg",
          "333299818_708855507399785_5953767387961361075_n.jpg",
          "333333861_659396929272292_4342218443069135289_n.jpg",
          "333435050_1359606911528036_8057931127093090549_n.jpg",
          "332883290_587315786328993_6921442562198452676_n.jpg",
          "332918158_192334026740248_3174293349870380_n.jpg",
        ],
        moreImagesLink:
          "https://www.facebook.com/SPAConline/posts/pfbid026GFJFa8deXSSUUvokHEkZAgm5K1W7h1DtWEWBZmEDEpMY8Yq1GmAgbZdoJquYG3Dl",
        folder: "/img/events/72AnnivFunRun/",
        postedBy: {
          img: "/img/spac_media_productions_logo.png",
          name: "SPAC Media Productions",
          link: "https://www.facebook.com/SPAConline",
        },
        photographers: ["SPAC Media Productions", "SPAC Media Club"],
      },
      {
        id: 2,
        title: "Sabbath Program",
        subtitle:
          "73rd Founding Anniversary of South Philippine Adventist College",
        description: "",
        date: "Feb 25 2023",
        photos: [
          "333111371_2409378109219346_2374924132460887009_n.jpg",
          "333198381_689245152938304_3570379875396180641_n.jpg",
          "333199908_727810632217137_8302458536153152965_n.jpg",
          "333211729_732676725036657_2407109951627349526_n.jpg",
          "333213889_756373122445662_2356285811427659957_n.jpg",
          "333215879_3309893855901213_8335703388438334845_n.jpg",
          "333216808_146305818296819_6638902761549943964_n.jpg",
          "333226665_884719909467123_6225051094709228754_n.jpg",
          "333228661_751966216179919_675742028861512747_n.jpg",
          "333241479_603454144963912_4406718341624628424_n.jpg",
          "333305456_1429570241184599_5304267069222839907_n.jpg",
          "333485795_921644515627635_3823501298498391122_n.jpg",
          "332857205_734077081494670_5197962142064587713_n.jpg",
          "332868602_3435561133436477_4394260660131483825_n.jpg",
          "332920356_185122747549576_6294435258391079270_n.jpg",
          "332931884_217675260794618_1377118086521559447_n.jpg",
          "332947760_574681277933151_3483683541087989703_n.jpg",
          "332963896_533824178849862_8002401430066555324_n.jpg",
          "333043418_699846851933694_7359307814412791412_n.jpg",
          "333090055_757691972614384_7737717320993320921_n.jpg",
          "333094786_631930522276861_7841601220714369792_n.jpg",
        ],
        moreImagesLink:
          "https://www.facebook.com/SPAConline/posts/pfbid0NkVWzb8Xiv1BEu8Ys541FLSZcQkGVWrYxmAUbfuZ7CkwMG66M9T4mCkbwBz6Xi3nl",
        folder: "/img/events/73AnnivSabbath/",
        postedBy: {
          img: "/img/spac_media_productions_logo.png",
          name: "SPAC Media Productions",
          link: "https://www.facebook.com/SPAConline",
        },
        photographers: ["SPAC Media Productions", "SPAC Media Club"],
      },
      {
        id: 3,
        title: "Animo Voces Holds Dedication Ceremony",
        description: `<p>The Animo Voces of South Philippine Adventist College Senior Academy holds its Dedication Program today, January 20, 2023. </p>
          <p>The group's chosen theme, A Thankful Heart, shows the outpouring of their gratitude to God for the many opportunities He allowed for the group to grow and be a blessing to the church and the community. </p>
          <p>Ms Rhody Laynn Morada, BSBA Chairperson and member of The Philippine Meistersingers, shared valuable insights of choral preparation and inspiration from her choral experiences which motivated the group to do more for the Lord's ministry. 
            <p>With a well-attended gathering of the group's supportive parents, friends and AV alumni, the 49  members and their sponsors, committed to continue the group's legacy of music ministry by sharing their talents for God's glory.</p>`,
        author: "Lizzi Casol",
        date: "Jan 20 2023",
        photos: [
          "325338513_1167071710840925_3635218277752242096_n.jpg",
          "325492522_733604098043542_8570502030726707041_n.jpg",
          "325764192_481262307527193_3366047370561230477_n.jpg",
          "325774606_852200232549185_5000458358241023921_n.jpg",
          "325789307_906605293853112_493399966397810642_n.jpg",
          "325892026_919400779081031_5133117631351331873_n.jpg",
          "325937044_544728217610371_3154475954274233140_n.jpg",
          "326064641_862315591646864_5783884772663963710_n.jpg",
          "326067361_501359322110432_571396028793069600_n.jpg",
          "326110781_5377991992305937_7531022685173751401_n.jpg",
          "326265191_1898475123833378_5629471060558096847_n.jpg",
          "326505867_723472522505715_7284731014454443417_n.jpg",
          "326686889_873342547050501_5663361249707136212_n.jpg",
        ],
        moreImagesLink:
          "https://www.facebook.com/SPAConline/posts/pfbid02oLtEuBBftSFRnmCXUtLh5yMb8TAz2s9wNcN6THRYtfPcF6Z8pe4963NiKdSFhzMFl",
        folder: "/img/events/animoVoces2023/",
        postedBy: {
          img: "/img/spac_media_productions_logo.png",
          name: "SPAC Media Productions",
          link: "https://www.facebook.com/SPAConline",
        },
        photographers: ["SPAC Media Productions", "SPAC Media Club"],
      },
      {
        id: 4,
        title: "10 Days of Prayer",
        date: "Jan 12 2023",
        photos: [
          "324090515_725549082506842_4696070452581108953_n.jpg",
          "324096195_1354236425411556_4290216101724569535_n.jpg",
          "324239371_692638142350481_6843938278326838171_n.jpg",
          "324414351_736970344185449_2784145581947188666_n.jpg",
          "324560317_903843617730854_2075917961696742954_n.jpg",
          "324586307_1107684089912347_5753085917696934842_n.jpg",
          "324859144_5090927547676617_2482080956966957793_n.jpg",
          "324919384_698382745163104_474373356127000583_n.jpg",
          "323889366_1646500499136668_6583656068445367661_n.jpg",
        ],
        moreImagesLink:
          "https://www.facebook.com/SPAConline/posts/pfbid0GFcDVwuC5UgMytnFcGvkRUApoSQGChKTkvKRCJR6icJnF54owAtr88QRZMhL6VpJl",
        folder: "/img/events/10daysofprayer/",
        postedBy: {
          img: "/img/spac_media_productions_logo.png",
          name: "SPAC Media Productions",
          link: "https://www.facebook.com/SPAConline",
        },
        photographers: ["SPAC Media Productions", "SPAC Media Club"],
      },
      {
        id: 5,
        title:
          "South Philippine Adventist College welcomes the Adventist Accrediting Agency (AAA) team",
        date: "Nov 28 2022",
        photos: [
          "316947055_8531602790243234_3574838681449367416_n.jpg",
          "316958969_8531601396910040_7203542740940898591_n.jpg",
          "317078677_8531601763576670_5007518360442035137_n.jpg",
          "317381299_8531601270243386_7431133763326020601_n.jpg",
          "317565886_8531600166910163_8684798911579224656_n.jpg",
          "316248826_8531603700243143_1353858815686696822_n.jpg",
          "316286633_8531602610243252_620723914247069654_n.jpg",
          "316289014_8531599020243611_8147881966224985686_n.jpg",
          "316290379_8531600706910109_3851636091150144398_n.jpg",
          "316554192_8531605900242923_4907253205182338060_n.jpg",
          "316554981_8531600320243481_6130508107888423977_n.jpg",
          "316671636_8531602813576565_1112302572526257206_n.jpg",
          "316675091_8531602520243261_4165362599003621106_n.jpg",
          "316675229_8531600176910162_4849824831356673865_n.jpg",
          "316682330_8531602660243247_168207414880535798_n.jpg",
          "316814275_8531599450243568_6671968697075413834_n.jpg",
        ],
        moreImagesLink:
          "https://www.facebook.com/SPAConline/posts/pfbid02i6xCZ9ktEZuKSyuB37LiJad6DjreqkLhrGFLL9jFLa57UfJcyGoh69WK7oYQgieyl",
        folder: "/img/events/aaaWelcome/",
        postedBy: {
          img: "/img/spac_media_productions_logo.png",
          name: "SPAC Media Productions",
          link: "https://www.facebook.com/SPAConline",
        },
        photographers: ["SPAC Media Productions", "SPAC Media Club"],
      },
      {
        id: 6,
        title: "WEEK OF PRAYER 2022",
        date: "Nov 7 2022",
        subtitle: "Theme: Dare to be",
        photos: [
          "313891981_8447064132030434_4117041917447497380_n.jpg",
          "313928029_8447058768697637_6451698651509370800_n.jpg",
          "314187054_8456101534460027_2638193671068513768_n.jpg",
          "314417279_8456538751082972_2373844652329925217_n.jpg",
          "314506606_8456105284459652_7838830616406767423_n.jpg",
          "314674435_8441654195904761_6945866485421583039_n.jpg",
          "314941669_8456102874459893_1923412135005066518_n.jpg",
          "314957627_8456538807749633_1146677343353534212_n.jpg",
          "315042181_8447240112012836_6074839355828786388_n.jpg",
          "315170983_8456103524459828_8688355395656016884_n.jpg",
          "315191223_8456538041083043_8122996819897325895_n.jpg",
          "315281627_8456104137793100_2491993542436051264_n.jpg",
          "315360316_8456102457793268_1900039060564764356_n.jpg",
          "313429869_8447062562030591_7442629782082878569_n.jpg",
          "313430694_8447233155346865_647511236327356382_n.jpg",
        ],
        folder: "/img/events/weekofprayer2022/",
        postedBy: {
          img: "/img/spac_media_productions_logo.png",
          name: "SPAC Media Productions",
          link: "https://www.facebook.com/SPAConline",
        },
        photographers: ["SPAC Media Productions", "SPAC Media Club"],
      },
      {
        id: 7,
        title: "Buwan ng Wika Celebration 2022",
        date: "Aug 23 2022",
        description: `<p>Ang South Philippine Adventist College ay nakikiisa sa selebrasyon ng Buwan ng Wika na may Temang: "Filipino at mga Katutubong Wika: Kasangkapan sa Pagtuklas at Paglikha." Dinaluhan ito ng mga mag-aaral at guro sa bawat departamento ng kolehiyo. Kitang-kita sa mga mata at ngiti ng mga dumalo ang Pagmamahal, pagkakaisa at Pagpapahalaga sa Wikang pambansa ng mga Mamamayang Pilipino.</p>
<p>Mabuhay ang Wikang Pambansa! Mabuhay ang mga Pilipino! Mabuhay ka SPAC!</p>
<p>Music belongs to the rightful owner:
Bagani - Anthony Castillo</p>`,
        iframeVideo: "https://www.youtube.com/embed/RRJ7qt2pYT8",
        source: "youtube",
        postedBy: {
          img: "/img/spac_media_club_logo.jpg",
          name: "SPAC Media Club",
          link: "https://www.facebook.com/SPACmedia",
        },
      },
      {
        id: 8,
        title: "SPAC Junior Academy holds Honors Day",
        date: "Feb 03 2023",
        description: `
<p></p>South Philippine Adventist College Academy commends the hard work and achievement of junior high students as it holds Honors Day for the 1st and 2nd Quarter of the Academic Year 2022-2023, last Friday, February 03, 2023, at the SPAC gymnasium. </p>
<p>Teachers and students wait in excitement as the program commenced. This is the first in-person academic recognition for the JHS after two years of virtual classes. Students were delighted to receive their certificates of recognition validating their competence and excellence. Among the highlights of the program are the words of encouragement from our dynamic Academy principal Sir Sem M. Pades LPT, M.A., who stresses that academic honors require efficiency and that a positive spiritual life equates to a positive academic life. </p>
<p>Soar high SPACians!</p>`,
        photos: [
          "331007232_1894429680894619_6277869234967304789_n.jpg",
          "329940037_910374546825974_3356696450526408717_n.jpg",
          "330188227_525092522940335_2521451561171931747_n.jpg",
          "330817374_597351135739708_2117192466678031765_n.jpg",
          "330852821_724599416036022_7026137908694219409_n.jpg",
          "330900971_501917492118326_6953754283911182459_n.jpg",
          "331010444_1565154210614911_2829865862388807517_n.jpg",
          "331138437_1785494405200041_2879201903481655146_n.jpg",
        ],
        folder: "/img/events/jahonorsday2022/",
        postedBy: {
          img: "/img/clarion.jpg",
          name: "Clarion - SPAC Academy",
          link: "https://www.facebook.com/profile.php?id=100075860596710",
        },
        author: "Sephryss Shaine V. Eguia",
        photographers: ["Clarion"],
      },
      {
        id: 9,
        title: "Hard Work Paid Off for SPACA Senior High Students ",
        date: "Jan 27 2023",
        description: `<p>Students of South Philippine Adventist College Senior Academy were awarded for their hard work as they celebrated the Senior Academy Honors Day 22'-23'. The event took place at the SPAC Gymnasium on January 27, 2023.
<p>After receiving digital certificates, students from both grade 11 and grade 12 were finally awarded physical awards for the first time in two years. Cheers and chants filled the place as the awardees went up individually to receive their recognition. One of the prominent parts of the program was the words of encouragement by Ma'am Sharon S. Maglupay, LPT, M.A., from the College's Teacher Education department. 
<p>The awarding of the achievers SPAC has produced was truly a sight to behold. 
  
  <p>Congratulations SPACians!`,
        photos: [
          "330854929_1573835263120232_7185681496529322189_n.jpg",
          "329454001_707395054387991_2249525920187323136_n.jpg",
          "329920005_527691782566099_3521061659322358554_n.jpg",
          "330195906_751777386081143_5592205099203876222_n.jpg",
          "330823107_708598924309272_6548331026529188457_n.jpg",
          "330850002_880661506554126_7872572297575513752_n.jpg",
          "330908441_885417962794810_1141041055376790505_n.jpg",
          "330928383_615245713942231_6400838041148367414_n.jpg",
        ],
        folder: "/img/events/sahonorsday2023/",
        postedBy: {
          img: "/img/clarion.jpg",
          name: "Clarion - SPAC Academy",
          link: "https://www.facebook.com/profile.php?id=100075860596710",
        },
        author: "Charles Gabriel F. Dela Torre ",
        photographers: ["Clarion"],
      },
      {
        id: 10,
        title: "Leaders in the Making",
        date: "Oct 23 2022",
        description: `<p>A three-day-long Pathfinder Leadership Camp was led by Pathfinder Director MG Jandphil Jazz Palomares on Oct. 21-23, 2022 at SPAC, for the aspiring pathfinder officers of SPACA junior high schools of S.Y. 22'-23'. </p><p>The camp started as aspiring officers arrived on Friday at 3 PM. The camp’s entirety totaled to 10 units. MG Daryl Guarino spoke the message for the devotional, entitled "Camping Experience with God". The orientation then followed by the Head Facilitator Alexania Ylya Pido. After, the Yoke of Obedience ceremony which symbolizes campers’ submission to the pathfinder club and to God commenced. Finally, the first day ended with a simple acquaintance for both campers and their facilitators.</p><p>On Saturday, the campers kicked off the day with an activity called "Trip to Canaan”, an event that strengthen the physical, mental, and spiritual abilities of each camper. MG Nitchelson Ray De Guzman offered the message “The Power of Words” for the Divine Service. The rest of Sabbath was then spent with spiritual activities such as “Your IQ on the Bible” “Meditating, and Talking to Jesus”, and "I see Jesus in you”, “Nature Walk” and "Heaven or Hell". After a long day, a social night was held to ease the campers and complete their camping experience.</p><p>On Sunday, a strategic activity entitled "Night Pass: Witsome Battle" occurred. Campers learned to apply first aid and escort an injured stealthily to avoid discovery by the facilitators who posed as dangers for their safety. After, activities like "Flag Capturing”, “Camp Bread making”, and the “Team Building” followed. The camp ended with an awarding ceremony for individuals who were the best unit captain, guidon bearer, scribe, and camper.</p><p>After three days of struggle, exhilaration, and enlightenment, the campers came back home molded anew, with a newfound discipline and a sense of leadership of a true pathfinder.</p>
`,
        photos: [
          "313308621_188931550312197_8713342685225916542_n.jpg",
          "312738557_188935203645165_2422334640686633035_n.jpg",
          "313111279_188933906978628_871176670988739148_n.jpg",
          "313175549_188931626978856_5143132363158316681_n.jpg",
          "313186366_188932736978745_9122971212438608469_n.jpg",
          "313206455_188933140312038_297824345542207030_n.jpg",
          "313260801_188934440311908_7577963604919815169_n.jpg",
          "313271229_188935433645142_4203640016861129386_n.jpg",
          "313273273_188931950312157_8606588644227397519_n.jpg",
          "313288465_188933596978659_810498680544429388_n.jpg",
          "313288847_188931486978870_3830678699507166471_n.jpg",
          "313315099_188933786978640_6217467288551336271_n.jpg",
          "313321496_188933536978665_3343971439413815404_n.jpg",
          "313352325_188935300311822_1573576433098688478_n.jpg",
          "313361046_188935156978503_1479007741834556990_n.jpg",
        ],
        folder: "/img/events/camping2022/",
        author: "Charles Gabriel F. Dela Torre",

        postedBy: {
          img: "/img/clarion.jpg",
          name: "Clarion - SPAC Academy",
          link: "https://www.facebook.com/profile.php?id=100075860596710",
        },
        photographers: ["Clarion"],
      },
      {
        id: 11,
        title: "Induction Ceremony Ignites the Pathfinder Spirit ",
        date: "Oct 21 2022",
        description: `<p>The Pathfinder Club of SPAC Academy held an Induction Ceremony on the 21st of October 2022, at South Philippine Adventist College gymnasium. The program was witnessed not just by teachers and staff but also by parents who traveled and came to see young soldiers be dedicated to be trained for the service of Christ. </p>
<p>With the echoing beat of sound and music, inductees marched in perfect accord as they move forward, showing unity and the dignity of a true Pathfinder. Many of the Pathfinder Master Guides from the campus were present in support of the program. MG Nitchelson Ray Deguzman welcomed and thanked the spectators, and MG Don Leo Garilva II, the keynote speaker, encouraged the young servicemen to be of use for God's glory.</p>
<p>The ceremonial undertakings for the induction proper commenced which included the lighting of the candles that symbolized the spirit of the pathfindering, as well as the six pathfinder classes namely; friend, companion, explorer, ranger, voyager, and guide. The candles that embodied the Pathfinder pledge and law were also lit. MG Jandphil Jazz Palomares, the Director of SPACA Pathfinder Club accepted the inductees as members of the organization. With a dedicatory prayer by the keynote speaker, students' commitment to being part of the Pathfinder ministry was sanctified.</p>`,
        photos: [
          "311520790_188762800329072_7328030976385452649_n.jpg",
          "311585320_188762693662416_3646684902075823787_n.jpg",
          "312934433_188763360329016_5715813346919850373_n.jpg",
          "313034470_188762833662402_4425015619291176366_n.jpg",
          "313166750_188762676995751_15404056208892215_n.jpg",
          "313171208_188762943662391_7576466085050007590_n.jpg",
          "313200948_188762926995726_8981069787308546455_n.jpg",
          "313254142_188762770329075_8237491611333454617_n.jpg",
          "313298035_188763193662366_748135769503452925_n.jpg",
          "313302962_188762720329080_6352936227489353894_n.jpg",
        ],
        folder: "/img/events/pathfinderinduction/",
        author: "Charles Gabriel F. Dela Torre",

        postedBy: {
          img: "/img/clarion.jpg",
          name: "Clarion - SPAC Academy",
          link: "https://www.facebook.com/profile.php?id=100075860596710",
        },
        photographers: ["Clarion"],
      },
      {
        id: 12,
        title: "Week Of Prayer 2022 Highlights",
        date: "Nov 9 2022",
        source: "facebook",
        postedBy: {
          img: "/img/spac_media_productions_logo.png",
          name: "SPAC Media Productions",
          link: "https://www.facebook.com/SPAConline",
        },
        photographers: ["SPAC Media", "SPAC Media Productions"],
        iframeVideo:
          "https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FSPAConline%2Fvideos%2F844736426958032&width=800&show_text=false&height=413&appId",
      },
      {
        id: 13,
        title: "NSTP Seminar 2022",
        date: "Oct 22 2022",
        source: "facebook",
        postedBy: {
          img: "/img/spac_media_productions_logo.png",
          name: "SPAC Media Productions",
          link: "https://www.facebook.com/SPAConline",
        },
        photographers: ["SPAC Media", "SPAC Media Productions"],
        iframeVideo:
          "https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2FSPAConline%2Fvideos%2F450271817174416&width=800&show_text=false&height=413&appId",
      },
      {
        id: 14,
        title: "Start of School Year 2022-2023",
        date: "Aug 15 2022",
        description: `<p>August 15, 2022, has been a testimony for South Philippine Adventist College! The school can now operate its first limited face-to-face classes. Praise the Lord for making SPAC continue to open its portals to give the students the quality Christian Education they deserve. Here are the highlights.</p>`,
        source: "youtube",
        iframeVideo: "https://www.youtube.com/embed/xWy5c9YUvKY",
        postedBy: {
          img: "/img/spac_media_club_logo.jpg",
          name: "SPAC Media Club",
          link: "https://www.facebook.com/SPACmedia",
        },
      },
      {
        id: 15,
        title: "College Student Week of Devotion",
        date: "Mar 5 2023",
        subtitle: "Theme: My Identity in Christ",
        folder: "/img/events/collegeswod2023/",
        photos: [
          "330810616_5998212360298344_2371453628327818955_n.jpg",
          "327162534_583469237167548_195468796816193107_n.jpg",
          "329911192_2340743689440297_4185419437762379807_n.jpg",
          "330482119_1347657422700665_9116632900088541745_n.jpg",
          "331562093_935973804073506_2828506937348156473_n.jpg",
          "334534861_161494766709810_5970518556488377207_n.jpg",
          "334584740_781494673574262_7897095792170435567_n.jpg",
          "335297228_169133479268027_339706952834748397_n.jpg",
          "334685459_6091417920925489_6093758190408538618_n.jpg",
          "334717841_1260205118194244_5217968700580990055_n.jpg",
          "334945796_715266443631154_4258514619849129116_n.jpg",
          "335099658_640890717842725_4107289853496512205_n.jpg",
          "335112049_1189824048384473_2656224785978320151_n.jpg",
          "335314694_159477976925614_2195598650489836600_n.jpg",
          "335200384_1445886566151668_3140594055445300341_n.jpg",
          "335248111_2319563528224866_7150306742464186258_n.jpg",
          "335283712_749843033511599_5949409960570120189_n.jpg",
          "335425155_971118567390028_6225939088068389447_n.jpg",
          "335320846_1015017286137269_226797312655848596_n.jpg",
          "335424852_774618257070376_4273475688862431381_n.jpg",
        ],
        moreImagesLink:
          "https://www.facebook.com/SPAConline/posts/pfbid0xY3oDphZctoP2Uu8fWNz1QogMZok78NFUir3qEfUiN3a8Xi9Bjqv1U8gKSyQTYMxl",
        postedBy: {
          img: "/img/spac_media_productions_logo.png",
          name: "SPAC Media Productions",
          link: "https://www.facebook.com/SPAConline",
        },
        photographers: ["SPAC Media Productions", "SPAC Media Club"],
      },
      {
        id: 16,
        title: "College Honors Day",
        date: "Mar 09 2023",
        folder: "/img/events/honorsday2023/",
        photos: [
          "335098890_218691304019121_5307224409310189991_n.jpg",
          "335126701_797023748662020_902220365718409256_n.jpg",
          "335156763_1307924326799795_3012127590640550471_n.jpg",
          "335169660_937658843904425_4271210688175922854_n.jpg",
          "335250881_167370235711882_2037364111231277753_n.jpg",
          "335251892_761930398683624_7509769639761194970_n.jpg",
          "335303159_219843617204475_7195610047036214636_n.jpg",
          "335307884_725161406018765_8490753029137546548_n.jpg",
          "335316008_539098448099392_9209851578619513767_n.jpg",
          "335338720_863845538009700_988621518324923331_n.jpg",
          "335432991_172644845534700_1168781439250094903_n.jpg",
          "335436141_224497700064186_3005216956239949579_n.jpg",
          "335454980_734366411622411_6730644675124657272_n.jpg",
          "335457737_580528637335598_7257884764894986769_n.jpg",
          "335472778_111282115182511_5854266901230391105_n.jpg",
        ],
        moreImagesLink:
          "https://www.facebook.com/SPAConline/posts/pfbid02Mv1489VLFkeBUGSk2ge66HHodxyXPdSFQEMG4unRiE3rjpkot1f8uX88Mk5j2xerl",
        postedBy: {
          img: "/img/spac_media_productions_logo.png",
          name: "SPAC Media Productions",
          link: "https://www.facebook.com/SPAConline",
        },
        photographers: ["SPAC Media Productions", "SPAC Media Club"],
      },
      {
        id: 17,
        title: "Academy Pathfinder Tactical Inspection",
        date: "Mar 08 2023",
        folder: "/img/events/2023tactical/",
        photos: [
          "335098899_924617835242325_4958129877863517760_n.jpg",
          "335135418_1652976881834679_1980718125211931115_n.jpg",
          "335138616_662002099267700_383097996517919749_n.jpg",
          "335139456_139285152128170_5117428635937694459_n.jpg",
          "335144970_775615597566014_8779710859259219470_n.jpg",
          "335154936_1611662576001068_5310081151191047730_n.jpg",
          "335179492_113968971639697_7192548046819921293_n.jpg",
          "335183603_5521975551237917_6588221889716161842_n.jpg",
          "335211551_1382782582519867_7350441124425278206_n.jpg",
          "335212605_607426641232113_6577102082786036533_n.jpg",
          "335221004_3488533091366454_7067968420357579690_n.jpg",
          "335222553_4266406643583630_3070149564512263006_n.jpg",
          "335248201_1236167683958235_4675646900009043499_n.jpg",
          "335257134_767699328246223_3468178717873014081_n.jpg",
          "335258421_258509283166251_5807697995146313020_n.jpg",
          "335266011_589552613105803_3028485362776180042_n.jpg",
          "335295730_952321905778491_1153918814812984606_n.jpg",
          "335298050_1146220516047935_2631079422235077325_n.jpg",
          "335300393_2875918569205977_3514259171807539413_n.jpg",
          "335304920_735799018272157_276426216076495119_n.jpg",
          "335315435_1375130036673892_2033161082430822253_n.jpg",
          "335399005_1590802304755989_4576583609165820707_n.jpg",
          "335404796_930863241436911_3505650497596842973_n.jpg",
          "335410963_746901257000600_7314476802350935403_n.jpg",
          "335414317_3506499659586996_1684394765558496519_n.jpg",
          "335414360_598354795516703_8720437379765412958_n.jpg",
          "335434050_759301482126475_5408684254219285531_n.jpg",
          "335095036_894112241806988_3106664660865544193_n.jpg",
        ],
        moreImagesLink:
          "https://www.facebook.com/SPAConline/posts/pfbid02xqQu44Sfdt2WrpjgQ9giMfJ7i93e8THaNtfo9m1ZfFQywVN81BErD6FXa5ZBtYtfl",
        postedBy: {
          img: "/img/spac_media_productions_logo.png",
          name: "SPAC Media Productions",
          link: "https://www.facebook.com/SPAConline",
        },
        photographers: ["SPAC Media Productions", "SPAC Media Club"],
      },
      {
        id: 18,
        title: "HUGYAW 2022",
        subtitle: "Theme: Tapuson ang Kahidlaw, Magsadya Kita sa Hugyaw",
        date: "Nov 30 2022",
        folder: "/img/events/hugyaw2022/",
        photos: [
          "314005375_510358927798308_3054040185404325841_n.jpg",
          "315758761_510356371131897_2528165332616663858_n.jpg",
          "315845107_510346654466202_6214480359045002675_n.jpg",
          "316099960_510355107798690_7717522366995191903_n.jpg",
          "316535274_510346581132876_6716938617839826434_n.jpg",
          "316537339_510359187798282_6327756735749822154_n.jpg",
          "316952041_510346364466231_441064700467814393_n.jpg",
          "317277839_511945300973004_2229630447544736869_n.jpg",
          "317509927_511947940972740_5838049907830903642_n.jpg",
          "317556463_511944344306433_6540068748168202527_n.jpg",
          "317624509_514016694099198_6243676315350102328_n.jpg",
          "317749835_514413200726214_2768109071963950103_n.jpg",
          "317869027_512877100879824_2020107536462095875_n.jpg",
          "317939999_511947880972746_171399334830447851_n.jpg",
          "318027159_511001944400673_8860777321323079821_n.jpg",
          "318082297_512878104213057_4458758975637080783_n.jpg",
          "318121610_512842210883313_5247536606298991904_n.jpg",
          "318178876_514407130726821_7677005187807878666_n.jpg",
          "318184742_514421034058764_8672825978319003673_n.jpg",
          "318208704_514403054060562_911757779818502746_n.jpg",
          "318308523_512767404224127_1089128067318517369_n.jpg",
          "318317262_513662667467934_4718212652529800721_n.jpg",
          "318319461_514399317394269_6778968079052224932_n.jpg",
          "318329369_514423927391808_647326878201328388_n.jpg",
          "318344689_513664970801037_1578113238728118922_n.jpg",
          "318418601_517318030435731_696584765854266720_n.jpg",
          "318423638_514410764059791_8648050205806726886_n.jpg",
          "318502227_513661020801432_2758964353200293339_n.jpg",
          "318567117_513660630801471_5312195769217062197_n.jpg",
          "318637555_514038754096992_5714773973364703964_n.jpg",
          "319320069_517319123768955_2106604226579829902_n.jpg",
          "312503232_512844234216444_1273711411357517447_n.jpg",
        ],
        moreImagesLink:
          "https://www.facebook.com/SPACmedia/posts/pfbid02b35ztYEi4dXH1fNLKD1CwkcE1zPaFoqSGsBSHMXAWRigrpGHSLgdvSs7eKpJNviyl",
        postedBy: {
          img: "/img/spac_media_club_logo.jpg",
          name: "SPAC Media Club",
          link: "https://www.facebook.com/SPACmedia",
        },
        photographers: ["SPAC Media Club"],
      },
      {
        id: 19,
        title: "Teacher Education Pinning Ceremony",
        date: "Mar 10 2023",
        folder: "/img/events/tedpining2023/",
        photos: [
          "328336068_147232917947966_6893367293894813579_n.jpg",
          "330335006_596657422314528_3168067808172055021_n.jpg",
          "330345993_1946121469085309_39556324725163495_n.jpg",
          "330394593_513587997634386_7902657152699710500_n.jpg",
          "330423969_593016382443319_7084849936109524658_n.jpg",
          "330506808_867498697646887_8993737772336786301_n.jpg",
          "330548324_590778372955617_2964995694903391603_n.jpg",
          "330567729_243158528140298_5673624401101285845_n.jpg",
          "330602444_2175494799307350_5447040327271803037_n.jpg",
          "335146629_606150311373633_8234421863267555139_n.jpg",
          "335148980_201890575854739_509242458634375583_n.jpg",
          "335186959_1257034658504581_8190387867360307482_n.jpg",
          "335248195_932742964829851_788605886511838919_n.jpg",
          "335265993_781492173308498_9054193116907518209_n.jpg",
          "335291650_230542639373448_5964249309556900917_n.jpg",
          "335335784_5999196733481510_3047400600788904060_n.jpg",
          "335370716_870555240673578_6720069231858811866_n.jpg",
          "335386095_595346389312547_2394402644797449869_n.jpg",
          "335424540_3430968890514168_2147335487312976111_n.jpg",
          "335587384_894091391826674_9013353644220694441_n.jpg",
        ],
        moreImagesLink:
          "https://www.facebook.com/SPACmedia/posts/pfbid02f15pWbMB3haAwZjVheQybM81HzttpapCgo6ZywwnaQ9XYgQ8R79XyTh3EPVRz1Mol",
        postedBy: {
          img: "/img/spac_media_club_logo.jpg",
          name: "SPAC Media Club",
          link: "https://www.facebook.com/SPACmedia",
        },
        photographers: ["SPAC Media Club"],
      },
      {
        id: 20,
        title: "SPAC Celebrates Filipino Food Month",
        subtitle: "Theme: Pagkaing Sariling Atin, Mahalin at Pagyamanin",
        date: "April 05 2023",
        folder: "/img/events/filipinofoodmonth/",
        photos: [
          "339301773_648684806987751_2492999608010480398_n.jpg",
          "339323846_201749652563636_4422167508100887856_n.jpg",
          "339331087_940721023738197_1912526362559187111_n.jpg",
          "339466288_1667824583665836_8950806846584774858_n.jpg",
          "339477262_889014082389195_9089999262129215442_n.jpg",
          "339569821_928674938371150_8598577514004041586_n.jpg",
          "339572823_135315419344365_6365291847667865832_n.jpg",
          "339579950_603689531375956_6914815435855002318_n.jpg",
          "339617192_3377319742515728_4560752971363306965_n.jpg",
          "339623686_1456233855184584_7335734629463985381_n.jpg",
          "339630663_938777967569371_1590639634152488906_n.jpg",
          "339640138_666477345244580_497305878185616139_n.jpg",
          "339649654_6472280496138333_2975810320795354915_n.jpg",
          "339654920_1053571578938151_8733941279134515288_n.jpg",
          "339697545_177834388412614_1249519502713325258_n.jpg",
        ],
        moreImagesLink:
          "https://www.facebook.com/SPAConline/posts/pfbid03nSQ1w7Lsf1vntTkZfaY4TbBM8b2X3GWj5UtTgMS7jrtY6wf6mYzoNnYvBtmhE4Gl",
        postedBy: {
          img: "/img/spac_media_productions_logo.png",
          name: "SPAC Media Productions",
          link: "https://www.facebook.com/SPAConline",
        },
        photographers: ["SPAC Media Productions", "SPAC Media Club"],
      },
      {
        id: 21,
        title: "Academy Intramurals",
        subtitle: "Theme: Kaminghuy Ihabuy, Kalingaw Ibayan",
        date: "April 01 2023",
        folder: "/img/events/jaintrams2023/",
        photos: [
          "338592780_3552306428375853_5281340814958931072_n.jpg",
          "338566707_760779255715404_8592488540620923236_n.jpg",
          "338678534_226007180089934_6142238266026836967_n.jpg",
          "338699336_783404276150419_1734667050043602802_n.jpg",
          "338859122_1192836304739387_8406128286264390_n.jpg",
          "339009016_1893255877717642_7929984805890577182_n.jpg",
          "339133325_929041455088096_8442005054537892271_n.jpg",
          "339134021_896918474718417_5424876743662169642_n.jpg",
          "339147674_3139455266347010_482455408556830725_n.jpg",
          "339148611_929724811666629_6690288656507998940_n.jpg",
          "339153004_594249316088081_6957589597836202207_n.jpg",
          "339153451_754786276222622_4355448250776930594_n.jpg",
          "339161481_1286225025661903_5626862579182760665_n.jpg",
          "339190847_650468110223686_362162665032755999_n.jpg",
          "339245432_1224144175159611_3060633292901070931_n.jpg",
          "339258424_186895847006310_8269441866033533384_n.jpg",
          "339260585_709174110956830_3698917559685408692_n.jpg",
          "339302900_1707890769668293_1706206083285992066_n.jpg",
          "339315257_794813548271828_8712087794266315922_n.jpg",
          "339398744_922646785545429_7592442076074920046_n.jpg",
          "339421078_612046390782458_5908618037661899289_n.jpg",
          "339433948_615394003787701_2257478061491308248_n.jpg",
          "339435695_6266533483403338_2115905376900711215_n.jpg",
          "339448623_609837921032199_8812002705445990090_n.jpg",
          "339468836_779753050246148_6726111450627318865_n.jpg",
          "339469637_1446847012755610_7164446398106104534_n.jpg",
          "339482364_1347439632500306_8134621960867650322_n.jpg",
          "339484594_2372792192894307_2979248254075432851_n.jpg",
          "339565105_169634179301172_6870912524332924561_n.jpg",
          "339623679_1409527849792465_222612486682686885_n.jpg",
        ],
        moreImagesLink:
          "https://www.facebook.com/SPACAcademy/posts/pfbid02HM5WKmCvKzSeT1qHVzzGZUQvDYiFyxkEYeGc6QWHn9RvLDrxpReBcSsWiM6BWAYzl",
        postedBy: {
          img: "/img/basic_ed_logo.jpg",
          name: "SPAC Academy",
          link: "https://www.facebook.com/SPACAcademy",
        },
        photographers: ["ASC Media"],
      },
      {
        id: 22,
        title: "Senior's Retreat of LAMDAMAN senior class batch 2023",
        subtitle: "Theme: Illuminate the Reflection of God: A Beacon of Light",
        date: "April 14 2023",
        folder: "/img/events/2023seniorsretreat/",
        photos: [
          "340750329_751727309757831_4919855369301642316_n.jpg",
          "340838797_200347369419556_2039167406163724039_n.jpg",
          "340968512_1239332126723565_111317687780330012_n.jpg",
          "340991418_965982057920959_165157171891114662_n.jpg",
          "340996304_560132399308851_886427988169776474_n.jpg",
          "341020478_614099846958825_6566482862659858821_n.jpg",
          "341025742_192352156906493_3735610590930056952_n.jpg",
          "341032578_1523393368188104_1256401484000961947_n.jpg",
          "341036487_551770970426105_426045584220770249_n.jpg",
          "341148203_1973942742957820_791220024290124641_n.jpg",
          "341186204_756696706199598_600520574754529821_n.jpg",
          "341283312_242396638190280_5359316567137793176_n.jpg",
          "341283427_767138728253586_7077424069795061786_n.jpg",
          "341314975_691928779402879_8670970454916555061_n.jpg",
          "341340054_2226259114244981_4624812113119381656_n.jpg",
          "341344161_747903330135130_5488776167567873464_n.jpg",
          "341352942_198302692956628_416733111761764028_n.jpg",
          "341372160_1265328914364825_3253786059280848475_n.jpg",
          "341445273_931559437986108_6928079434408497309_n.jpg",
          "341471016_955520478906505_3550126051933334400_n.jpg",
          "341471081_1387546842085111_4853325617575468666_n.jpg",
          "341497196_760159885667589_6737107186308694609_n.jpg",
          "341503493_783680889580802_5683192091593416249_n.jpg",
          "341575292_3157233521235024_6500707179575766867_n.jpg",
          "341620586_730531652083130_133352497858019403_n.jpg",
          "341665184_1261783751413240_6123282998544290563_n.jpg",
          "341670713_969441994225241_1987892994762756631_n.jpg",
          "341677480_1199432374075796_7566967225722368598_n.jpg",
          "341691561_769442624529939_4168681245192097137_n.jpg",
          "341769901_1325652698009384_5127419643531434721_n.jpg",
        ],
        moreImagesLink:
          "https://www.facebook.com/SPAConline/posts/pfbid0cuE4nTPV3Es2FJY2upKSK7bpanX7ck8oAr97MUCJRu2hKsNTPTp3M7s4pBQzZozdl",
        postedBy: {
          img: "/img/spac_media_productions_logo.png",
          name: "SPAC Media Productions",
          link: "https://www.facebook.com/SPAConline",
        },
        photographers: ["SPAC Media Productions"],
      },
      {
        id: 23,
        title: "Southern Ambassadors Dedication Ceremony",
        description: `<p>The Southern Ambassadors of SPAC organized a Dedication Ceremony last April 28, 2023, with the theme: "Our Tribute." The group filled the gymnasium with praises and songs unto God. With courage and glory, the Southern Ambassadors are now ready to serve God through the singing ministry.</p>`,
        date: "April 28 2023",
        folder: "/img/events/sadedication2023/",
        photos: [
          "343799560_1295049144416503_7287405734051948767_n.jpg",
          "343916586_681932377071254_6900505505113777061_n.jpg",
          "343922950_752603503070466_6817826972255433271_n.jpg",
          "343989152_756531689468899_4894714232755101550_n.jpg",
          "344069049_151649177672706_4722303406050597928_n.jpg",
          "344233062_778878443797283_5694903037164781245_n.jpg",
          "344552096_233235362724232_2707359305720724258_n.jpg",
          "344748226_857522712397045_2570948047682185048_n.jpg",
          "343941896_630227355219752_6643580886949296291_n.jpg",
          "344333498_120199104388506_1324791303273747948_n.jpg",
        ],
        moreImagesLink:
          "https://www.facebook.com/SPACmedia/posts/pfbid02HFruBg5aTnKv2tRE2Vwcpag5M41SGKWq3YQuJAQJygV55QeHJtNvxi6TPijKRR9Al",
        postedBy: {
          img: "/img/spac_media_club_logo.jpg",
          name: "SPAC Media Club",
          link: "https://www.facebook.com/SPACmedia",
        },
        photographers: ["SPAC Media Club"],
      },
      {
        id: 24,
        title: "Music Recital",
        date: "May 14 2023",
        folder: "/img/events/2023musicrecital/",
        photos: [
          "349192797_158587157187915_4429428316445936430_n.jpg",
          "349560779_529680802534411_4843798884120202534_n.jpg",
          "349861469_133499839699930_4623829963686523802_n.jpg",
          "349912826_228968353201252_7642598981648242429_n.jpg",
          "349989107_637841551190076_8478479364933373259_n.jpg",
          "349991952_911843319925566_7592715506827241810_n.jpg",
          "350107798_1228290221156372_2423695209988068432_n.jpg",
          "350126085_1007386480163299_2582629266917469244_n.jpg",
          "350133390_469755178682215_4468663515279331417_n.jpg",
          "350460569_768311334930945_9068883010792849767_n.jpg",
          "350522833_907127990378623_6848732691268961574_n.jpg",
        ],
        moreImagesLink:
          "https://www.facebook.com/SPAConline/posts/pfbid0cpmpm2BfWZM5YFG7mMjUpL2WHBxdFkJAJmtgbhFsRegQa2nnHhpZXcFm1Lmb7Yail",
        postedBy: {
          img: "/img/spac_media_productions_logo.png",
          name: "SPAC Media Productions",
          link: "https://www.facebook.com/SPAConline",
        },
        photographers: ["SPAC Media Productions"],
      },
      {
        id: 25,
        title: "Astral Echoes Alumni Homecoming & Reunion Concert",
        subtitle: "Astral Echoes: 14 Years of Echoing God's Love through Music",
        description: `<p>The Astral Echoes of South Philippine Adventist College has been one of the official singing groups in the college existing since 2009 and recently the group celebrated its 14 years of echoing God's message of love through music. In line with the celebration of the group's 14th year, the group organized an Alumni Homecoming and Reunion Concert for a Cause with the theme: God's Faithfulness: Legacy of Voices in Harmony.</p>
        <p>The concert was participated by the Astral Echoes Alumni from the year ut was established down to the present members. They sing series of songs portraying the goodness of God to the Astral Echoes for bringing and preserving the legacy of voices for over 14 years. The concert was primarily realized to raise funds for the on-going construction of the SPAC Church and praise the Lord, the group was able to give something for the Church.</p>
     <p>After the concert, the group was able to recollect and reconnect with their alumni, sponsors, and members during the following day at Leling Beach. They were able to share lasting memories, play games, and share a nutritious meal. Above all the Astral Echoes Alumni Homecoming and Reunion was a success. Leaving the group eager to sing across galaxies the song of hope for more years to come. Indeed the celebration of God's goodness doesn't end here, the Astral Echoes will always be ready for more breakthroughs ahead.</p>`,
        date: "May 6 2023",
        folder: "/img/events/2023aereunion/",
        photos: [
          "345634038_178396568487187_5713986898806315620_n.jpg",
          "344824015_975601786912391_6551513393508809349_n.jpg",
          "344851717_100164833084583_1579720956123485111_n.jpg",
          "344905014_192865290282423_2398598749488197145_n.jpg",
          "345593890_543581464647599_3198065757455825542_n.jpg",
          "345638718_214177997997961_7531822697838456279_n.jpg",
          "345653563_963706554761095_8252742821014666330_n.jpg",
          "345860670_571532664835342_6137029414899885596_n.jpg",
          "345865574_254406773782621_4447061254713444606_n.jpg",
          "345901596_619406633444624_7315789767009742719_n.jpg",
          "345904576_254642430269299_8128531858977047505_n.jpg",
          "345911033_620454010128361_1241244876103987580_n.jpg",
          "345925332_231504382936849_5069766833533414683_n.jpg",
          "346032120_1937457993258325_8742154725411390455_n.jpg",
          "346036293_548645264086404_4722463201754686746_n.jpg",
          "346037843_771169877955961_4113972850656958175_n.jpg",
          "346039198_168171972571926_7078234547900695846_n.jpg",
          "346041742_6211341362259256_2323298583993226434_n.jpg",
          "346042224_1324781335050320_8113126046846699103_n.jpg",
          "346045302_1394227724747331_1198731781232195522_n.jpg",
          "346303872_1287056228563570_6494554233112244624_n.jpg",
        ],
        author: "Glenn Dale E. Estañol",
        moreImagesLink:
          "https://www.facebook.com/astralechoes/posts/pfbid02KipMGy2ZhS3wwfh1v8hfwCQggAZmC3kuD8SMfd7stxV8Ps7QsZ3LhZQDN1xu1uwul",
        postedBy: {
          img: "/img/ae_logo.jpg",
          name: "Astral Echoes Official",
          link: "https://www.facebook.com/astralechoes",
        },
        photographers: ["SPAC Media Productions", "SPAC Media Club"],
      },
      {
        id: 26,
        title:
          "Elementary Cluster Moving Up, Recognition and Graduation Ceremony",
        date: "May 24 2023",
        folder: "/img/events/2023elemgrad/",
        photos: [
          "348838136_1688779428228211_7040834336424476735_n.jpg",
          "348870190_157989043913757_5299459399893625058_n.jpg",
          "348912357_748687920380044_3280463892390734406_n.jpg",
          "348931451_913617406528447_3874417082956496593_n.jpg",
          "348971911_581968137337514_8994671289123193842_n.jpg",
          "348984603_226146480203882_7120174640765163499_n.jpg",
          "349076339_2457141317803961_7763219479906144389_n.jpg",
          "349078628_1962473144113912_8969053622302592537_n.jpg",
          "349099070_207007248881825_2032910178394455117_n.jpg",
          "349151850_1032157374857839_3826930847681585717_n.jpg",
          "349170764_3305264926451088_3054763190828855718_n.jpg",
          "349185189_782054483334167_2703966704937870947_n.jpg",
          "349195863_903335890761306_1806101255069676085_n.jpg",
          "349198194_982270319879012_7192868256699804759_n.jpg",
          "349293308_556962159953048_8437646883597939751_n.jpg",
          "349307977_1884062228636269_2599422591869050229_n.jpg",
          "349310513_633221308863834_2031355117908963569_n.jpg",
          "349316232_767238675084284_6749658882000051794_n.jpg",
          "349330653_241339995211513_3466816167864378580_n.jpg",
          "349351396_567397592212717_2845774414396620061_n.jpg",
          "349358267_550531597268908_5626860668074852229_n.jpg",
          "349652506_987351525967746_3342154986636692890_n.jpg",
          "349654919_745279217297997_3052871256914945661_n.jpg",
          "349753051_637268855100453_5670294454405376909_n.jpg",
        ],
        moreImagesLink:
          "https://www.facebook.com/SPAConline/posts/pfbid02MLr4SEDRBkDef2aTXpi1VMu9VARFgGPeikH2H5DktXCisCPNEHBTWNXzPMpdv3kol",
        postedBy: {
          img: "/img/spac_media_productions_logo.png",
          name: "SPAC Media Productions",
          link: "https://www.facebook.com/SPAConline",
        },
        photographers: ["SPAC Media Productions"],
      },
      {
        id: 27,
        title: "Junior Academy Cluster Moving Up and Recognition Ceremony",
        date: "May 25 2023",
        folder: "/img/events/2023jamovingup/",
        photos: [
          "349142495_987440785950065_2588297307182543718_n.jpg",
          "348995846_792864285465011_4552857175125081285_n.jpg",
          "349010999_1445893839283700_7351792029784738628_n.jpg",
          "349078161_592927252928939_1982768036204982830_n.jpg",
          "349079901_1319781205553898_4314815507740480945_n.jpg",
          "349089807_2186482421552635_3452616878739878887_n.jpg",
          "349092468_535204045296775_7702582600546832235_n.jpg",
          "349111476_578377941134709_2494420197787460222_n.jpg",
          "349153134_952865172576629_6638452271342942832_n.jpg",
          "349175035_792664328915817_5437656938407838969_n.jpg",
          "349327184_1639152063265259_3995460142584109785_n.jpg",
          "349334236_168769116164698_4858492046921877269_n.jpg",
          "349353239_202702239368000_1906763127262109910_n.jpg",
          "349561752_585354310243973_6648511648931772519_n.jpg",
          "349591107_1718606308598173_7540576552357878699_n.jpg",
          "349599431_672200654786213_900014313241752628_n.jpg",
          "349744292_186299677331771_4397302663068515945_n.jpg",
          "349854937_977470417002638_2779380499207821886_n.jpg",
          "350103797_171882532509311_475061289316544371_n.jpg",
          "350115885_204143832452599_4228617546524073900_n.jpg",
        ],
        moreImagesLink:
          "https://www.facebook.com/SPAConline/posts/pfbid0324Xw3gq5FUcMtGDWGwuDndBYeg3PbwZ4AEvAy3eKpCSJndGzhN69FDHYfFabsrGHl",
        postedBy: {
          img: "/img/spac_media_productions_logo.png",
          name: "SPAC Media Productions",
          link: "https://www.facebook.com/SPAConline",
        },
        photographers: ["SPAC Media Productions"],
      },
      {
        id: 28,
        title:
          "Academy Senior's Retreat of Marthanoir senior high school batch 2023",
        date: "April 30 2023",
        folder: "/img/events/2023saretriet/",
        photos: [
          "344311517_973883483769468_6950465349303402794_n.jpg",
          "344091482_629910781879183_8921915823794127395_n.jpg",
          "344155609_254584967068916_5956566788473124064_n.jpg",
          "344202444_2193702211017263_6422825829716911698_n.jpg",
          "344217723_630650492244381_135629970269817693_n.jpg",
          "344250797_581877054037297_3110856973135064923_n.jpg",
          "344261225_229310289728681_8126820838509967999_n.jpg",
          "344264136_1151879635628529_8210457011589111303_n.jpg",
          "344292347_604584285053523_8681162650615871883_n.jpg",
          "344317365_1372275176897164_8485459328562438223_n.jpg",
          "344317616_1290971355159272_779032419193822791_n.jpg",
          "344328879_909887260069457_9206919975019854225_n.jpg",
          "344335094_122684997470229_7047716969858158067_n.jpg",
          "344351678_990932941804992_7252104362010588594_n.jpg",
          "344554265_153277111023566_6760784822720082708_n.jpg",
        ],
        moreImagesLink:
          "https://www.facebook.com/SPACAcademy/posts/pfbid0mYJoCQVWmr7QV6sSDhMbwPstqiVvav15JQRuW2fAhpTcXPK2viYppEjXJnoxeFo1l",
        postedBy: {
          img: "/img/basic_ed_logo.jpg",
          name: "SPAC Academy",
          link: "https://www.facebook.com/SPACAcademy",
        },
        photographers: ["ASC Media"],
      },
      {
        id: 29,
        title: "Marthanior '23 Graduation Exercises",
        subtitle: "Senior Academy Cluster",
        date: "May 28 2023",
        alt_date: "May 26-28 2023",
        folder: "/img/events/2023sagrad/",
        photos: [
          "350953152_830265045394697_1038884850063231708_n.jpg",
          "350265771_189037427436696_1377491277334657116_n.jpg",
          "350328511_940503370544079_8354362757288878380_n.jpg",
          "350345029_173513732071371_9173767360935109589_n.jpg",
          "350349696_909428286791467_9118317598085600947_n.jpg",
          "350473407_962564728527277_8379329545823247120_n.jpg",
          "350478623_1228192784499745_4199993683955066440_n.jpg",
          "350526817_942040057006060_5811083752113094127_n.jpg",
          "350528814_158868330499139_2891531182886346864_n.jpg",
          "350532475_789459849353720_3503565686092096777_n.jpg",
          "350538596_553942426908205_3697846195028565462_n.jpg",
          "350675410_763283095528314_3918911640257979342_n.jpg",
          "350681448_919424639127962_8221157616668546981_n.jpg",
          "350789501_255519103823689_7543576964788270090_n.jpg",
          "350805814_978688879811149_3201391062387486723_n.jpg",
          "350837108_589882739879720_3533689624507100409_n.jpg",
          "350867281_1178213172855795_6741823088073285365_n.jpg",
          "350923840_743912094082757_7653231255063272909_n.jpg",
          "350924767_2198539417001580_7938549716510851121_n.jpg",
          "350926663_782411449933886_8291657246295820046_n.jpg",
          "350928692_925194561882348_2081135087625321751_n.jpg",
          "350937591_1729471424135539_7306287732024023361_n.jpg",
          "350943473_757412392977630_7632422907798737930_n.jpg",
          "350951736_268581122344522_8532003213829480105_n.jpg",
          "351005876_1639430189890910_6635303648370082347_n.jpg",
          "351136969_970904367277412_6416299665113858476_n.jpg",
        ],
        moreImagesLink:
          "https://www.facebook.com/SPAConline/posts/pfbid02meLNyKj6DgQTpDTFp4SCNWPdLN28pdNN93NwnjBHL8kEBRw5kiHKy6q72Ps9nPGNl",
        postedBy: {
          img: "/img/spac_media_productions_logo.png",
          name: "SPAC Media Productions",
          link: "https://www.facebook.com/SPAConline",
        },
        photographers: ["SPAC Media Productions"],
      },
      {
        id: 29,
        title: "Landaman '23 Graduation Exercises",
        date: "June 4 2023",
        alt_date: "June 2-4 2023",
        folder: "/img/events/2023landaman/",
        photos: [
          "350820141_1434598824025086_7450540469410016069_n.jpg",
          "350375205_812076716641406_9046262824524994281_n.jpg",
          "350496687_298860325799314_6520311205501799067_n.jpg",
          "350521044_774533860829710_1558934821869132382_n.jpg",
          "350661941_202372836034419_5216285122933518879_n.jpg",
          "350661942_3574729556130592_5061761983266757503_n.jpg",
          "350807147_207407005506254_1992760552930547362_n.jpg",
          "350842039_962896361502374_3850683662345735355_n.jpg",
          "350943806_274765924968364_4984517717328518749_n.jpg",
          "350945301_9280281802045583_8122887518286318944_n.jpg",
          "350957278_639832334333878_3369783689251053193_n.jpg",
          "350971707_1897410467298497_7540695741226877296_n.jpg",
          "350990270_628083682711496_277837628972752106_n.jpg",
          "351160207_1360420654521176_1385007869529419152_n.jpg",
          "351310474_173563918745981_5768276099594245373_n.jpg",
        ],
        moreImagesLink:
          "https://www.facebook.com/SPAConline/posts/pfbid0dooaeH4Mci6M2whfgmQQhE5RdSFdtS3wPdEYjmApquMDkNHLPPQriPbZnBKxgD1Dl",
        postedBy: {
          img: "/img/spac_media_productions_logo.png",
          name: "SPAC Media Productions",
          link: "https://www.facebook.com/SPAConline",
        },
        photographers: ["SPAC Media Productions"],
      },
      {
        id: 30,
        title: "Flag Raising Ceremony",
        subtitle: "Raising our flag, embracing our unity!",
        description:
          "SPAC holds the first of the monthly flag raising ceremonies for the academic year 2023-2024, celebrating our shared values and commitment to instilling the spirit of patriotism in the youth.",
        date: "Sep 4 2023",
        folder: "/img/events/FC_Sept4/",
        photos: [
          "DSC01995.jpg",
          "DJI_0444.jpg",
          "DJI_0421.jpg",
          "DJI_0435.jpg",
          "DJI_0426.jpg",
          "DJI_0439.jpg",
          "DSC01962.jpg",
          "DSC01964.jpg",
          "DSC01974.jpg",
          "DSC01976.jpg",
          "DSC01979.jpg",
          "DSC01987.jpg",
          "DSC01989.jpg",
          "DSC01990.jpg",
          "DSC02416.jpg",
          "DSC02417.jpg",
          "DSC02419.jpg",
          "DSC02423.jpg",
          "DSC02428.jpg",
          "DSC02430.jpg",
          "DSC02437.jpg",
          "DSC02440.jpg",
          "DSC02447.jpg",
        ],
        postedBy: {
          img: "/img/spac_media_productions_logo.png",
          name: "SPAC Media Productions",
          link: "https://www.facebook.com/SPAConline",
        },
        photographers: ["SPAC Media Productions"],
      },
      {
        id: 31,
        title: "Kulminasyon sa Buwan ng Wika",
        description: `Tungo sa Pagkakaisa at Pagpapahalaga sa Wika, ang mga Mag-aaral galing sa asignaturang Pagtuturo ng Filipino sa Elementarya ay nagsagawa ng isang pagdiriwang na Kulminasyon sa Buwan ng Wika na may temang: "Filipino at mga Katutubong Wika: Wika ng Kapayapaan, Seguridad, at Ingklusibong Pagpapatupad ng Katarungang Panlipunan". Ang programa ay nagpapakita ng iba't-ibang presentasyon tulad ng: Sabayang Pagbigkas, Kanta galing sa iba't-ibang lingwahe at mga Tula bilang patunay na tayo ay pinagdugtung-dugtong dahil sa wika.`,
        date: "Sep 1 2023",
        folder: "/img/events/2023buwanngwika/",
        photos: [
          "371544947_683545117146354_3383258697403167857_n.jpg",
          "371545936_683544860479713_7002297647769435851_n.jpg",
          "371846215_683544690479730_5155667177011982900_n.jpg",
          "371858438_683545140479685_1055633585289885634_n.jpg",
          "371912646_683545260479673_8561038486839857769_n.jpg",
          "371951690_683544390479760_3339778781448786940_n.jpg",
          "371980407_683544423813090_9104826975386876266_n.jpg",
          "372035919_683544337146432_3194776158866595521_n.jpg",
          "372621422_683544600479739_8821710229340851893_n.jpg",
          "372626487_683545013813031_9161284469708638933_n.jpg",
          "372636618_683545187146347_3400625266891361914_n.jpg",
          "372675971_683544723813060_4118593509102529059_n.jpg",
          "372683727_683545090479690_6346365037621047414_n.jpg",
          "372755499_683545330479666_8542070504108922017_n.jpg",
          "372829725_683544303813102_4951093669046754022_n.jpg",
          "373295555_683545047146361_607552259216764534_n.jpg",
        ],
        moreImagesLink:
          "https://www.facebook.com/SPACmedia/posts/pfbid02f43AWSuAEUVHWqJHDjiAHjT8FNuHTgWWoTmmHzFmPGyTg76gQDCe819kZf88FmD6l",
        postedBy: {
          img: "/img/spac_media_club_logo.jpg",
          name: "SPAC Media Club",
          link: "https://www.facebook.com/SPACmedia",
        },
        photographers: ["SPAC Media Club"],
      },

      {
        id: 33,
        title: "Journalism Workshop",
        date: "Sep 24 2023",
        folder: "/img/events/2023journalismworkshop/",
        photos: [
          "DSC04578.jpg",
          "DSC04562.jpg",
          "DSC04563.jpg",
          "DSC04567.jpg",
          "DSC04574.jpg",
          "DSC04583.jpg",
          "DSC04593.jpg",
          "DSC04598.jpg",
          "DSC04599.jpg",
          "DSC04601.jpg",
          "DSC04604.jpg",
          "DSC04607.jpg",
          "DSC04612.jpg",
          "DSC04613.jpg",
          "DSC05281.jpg",
          "DSC05291.jpg",
          "DSC05293.jpg",
          "DSC05298.jpg",
          "DSC05303.jpg",
          "DSC05308.jpg",
          "DSC05309.jpg",
          "DSC05312.jpg",
          "DSC05313.jpg",
          "DSC05314.jpg",
          "DSC05323.jpg",
          "DSC05325.jpg",
          "DSC05331.jpg",
          "DSC05334.jpg",
          "DSC05389.jpg",
          "DSC05402.jpg",
          "DSC05415.jpg",
          "DSC05416.jpg",
        ],
        moreImagesLink:
          "https://www.facebook.com/SPAConline/posts/pfbid02zhY4h5ocqepGLL2hZpkSbrgsWxyrxuN9KscaE22u7F2tzLSfpaESjX98izvX66A7l",
        postedBy: {
          img: "/img/spac_media_productions_logo.png",
          name: "SPAC Media Productions",
          link: "https://www.facebook.com/SPAConline",
        },
        photographers: ["SPAC Media Productions"],
        newsLink: "SPAC Hosts Journalism Workshop for Media Enthusiasts",
      },
      {
        id: 34,
        title: "Academy Spiritual Retreat",
        date: "Sep 9 2023",
        folder: "/img/events/2023academyspiritualretreat/",
        photos: [
          "376823806_639905291606554_2727095297464357625_n.jpg",
          "376851762_639861838277566_2205761625089809774_n.jpg",
          "377503827_639861278277622_4949880532654248065_n.jpg",
          "377506564_639861394944277_800579410327446336_n.jpg",
          "377510278_639861474944269_8741880291356694559_n.jpg",
          "377547790_639905224939894_5285378119818266280_n.jpg",
          "377551853_639861131610970_4679733882676036271_n.jpg",
          "377551954_639861508277599_8421323499270684414_n.jpg",
          "377571831_639861721610911_4056505416544961633_n.jpg",
          "377581756_639861304944286_6942796420552385903_n.jpg",
          "377902812_639861328277617_2183021073432803965_n.jpg",
          "377913605_639861071610976_7542084858294808523_n.jpg",
          "377926587_639861151610968_575292386953806809_n.jpg",
          "377953392_639861431610940_84118575613439261_n.jpg",
          "377983376_639861118277638_7598662870720591522_n.jpg",
        ],
        postedBy: {
          img: "/img/basic_ed_logo.jpg",
          name: "SPAC Academy",
          link: "https://www.facebook.com/SPACAcademy",
        },
        photographers: ["Academy News Portal"],
      },
      {
        id: 35,
        title: "SPAC Faculty Introduction Program",
        subtitle: "where knowledge meets inspiration",
        description: "Bringing brilliance to the forefront!",
        date: "Aug 24 2023",
        folder: "/img/events/2023introductiontofaculty/",
        photos: [
          "DJI_0388.jpg",
          "DJI_0392.jpg",
          "DSC00557.jpg",
          "DSC00564.jpg",
          "DSC00586.jpg",
          "DSC00594.jpg",
          "DSC00597.jpg",
          "DSC00603.jpg",
          "DSC00605.jpg",
          "DSC00607.jpg",
          "DSC00612.jpg",
          "DSC00631.jpg",
          "DSC00656.jpg",
          "DSC00663.jpg",
          "DSC00665.jpg",
          "DSC00667.jpg",
          "DSC00672.jpg",
          "DSC00678.jpg",
          "DSC00681.jpg",
          "DSC00687.jpg",
          "DSC00693.jpg",
          "DSC00698.jpg",
          "DSC00705.jpg",
          "DSC00714.jpg",
          "DSC00717.jpg",
          "DSC00727.jpg",
          "DSC00732.jpg",
          "IMG_0897.jpg",
          "IMG_0924.jpg",
          "IMG_0928.jpg",
          "IMG_0944.jpg",
          "IMG_0957.jpg",
          "IMG_0985.jpg",
          "IMG_0986.jpg",
          "IMG_0998.jpg",
          "IMG_1011.jpg",
        ],
        postedBy: {
          img: "/img/spac_media_productions_logo.png",
          name: "SPAC Media Productions",
          link: "https://www.facebook.com/SPAConline",
        },
        photographers: ["SPAC Media Productions"],
      },
      {
        id: 36,
        title: "Sabbath Retreat 2023",
        subtitle: `Theme: "Spending Time with God."`,
        description:
          "Reflecting, rejuvenating, and spending sacred moments with God.",
        date: "Sep 9 2023",
        folder: "/img/events/2023collegespiritualretreat/",
        photos: [
          "DSC00001.jpg",
          "DSC00007.jpg",
          "DSC00020.jpg",
          "DSC00031.jpg",
          "DSC00034.jpg",
          "DSC00066.jpg",
          "DSC00069.jpg",
          "DSC00132.jpg",
          "DSC00134.jpg",
          "DSC00144.jpg",
          "DSC00146.jpg",
          "DSC00152.jpg",
          "DSC00158.jpg",
          "DSC00206.jpg",
          "DSC00221.jpg",
          "DSC00232.jpg",
          "DSC00243.jpg",
          "DSC00256.jpg",
          "DSC00260.jpg",
          "DSC00269.jpg",
          "DSC00288.jpg",
          "DSC00301.jpg",
          "DSC00346.jpg",
          "DSC02879.jpg",
          "DSC02880.jpg",
          "DSC02888.jpg",
          "DSC02890.jpg",
          "DSC02914.jpg",
          "DSC02940.jpg",
          "DSC02997.jpg",
        ],
        postedBy: {
          img: "/img/spac_media_productions_logo.png",
          name: "SPAC Media Productions",
          link: "https://www.facebook.com/SPAConline",
        },
        photographers: ["SPAC Media Productions"],
      },
      {
        id: 37,
        title: "ACADEMY Acquaintance Party",
        subtitle: `Theme: 
        SSC "Kasadya sa Paghigalaay"
        JSC "Kalaay Isalikway, Kasadya ug Panagtagbo Ikalipay"`,
        date: "Sep 22 2023",
        folder: "/img/events/2023academyacquintance/",
        photos: [
          "384127380_649257360671347_7326472058124054066_n.jpg",
          "382972932_649259467337803_2191731045641372002_n.jpg",
          "383093451_649259680671115_6733349591081183565_n.jpg",
          "383322448_649258440671239_64764487601287735_n.jpg",
          "383348470_649258314004585_3385821049482274523_n.jpg",
          "383381399_649257817337968_3487167312908541941_n.jpg",
          "383381431_649257900671293_2741845768972157335_n.jpg",
          "383416838_649259520671131_7139671942091230246_n.jpg",
          "383428726_649257560671327_4386891526108063178_n.jpg",
          "383741574_649259754004441_5613564839114839502_n.jpg",
          "383744701_649259554004461_8095023738315098229_n.jpg",
          "383745407_649258237337926_5983786654719541727_n.jpg",
          "383747161_649257184004698_6708599658724261334_n.jpg",
          "383748100_649259007337849_3773649629999793579_n.jpg",
          "383748751_649257114004705_5964453032423011097_n.jpg",
          "383749265_649259094004507_2960183694142521642_n.jpg",
          "383751540_649257947337955_8575521909924811658_n.jpg",
          "383752958_649257057338044_2023092409013744789_n.jpg",
          "383754975_649257270671356_4752347372062742417_n.jpg",
          "383770187_649257334004683_5908232786117654874_n.jpg",
          "383772511_649257530671330_5217110636577064552_n.jpg",
          "383772557_649258784004538_3560611779603641390_n.jpg",
          "383775636_649259870671096_925973079960949652_n.jpg",
          "383780211_649257364004680_6332834690168672880_n.jpg",
          "383780521_649257284004688_6497454948348128301_n.jpg",
          "384117621_649258037337946_8887887854781051515_n.jpg",
        ],
        moreImagesLink:
          "https://www.facebook.com/SPACAcademy/posts/pfbid02CPJ4Qpx1tmU1otDGFMCGRcKc72jGHdTRXAYAYQitUtS4MV3CSDc6aJaZkZjGxupul",
        postedBy: {
          img: "/img/basic_ed_logo.jpg",
          name: "SPAC Academy",
          link: "https://www.facebook.com/SPACAcademy",
        },
        photographers: ["Academy News Portal"],
      },
      {
        id: 38,
        title: "World Teachers' Day Celebration",
        subtitle: `"Empowering Educators: Strengthening Resilience, Building Sustainability"`,
        date: "Oct 5 2023",
        folder: "/img/events/2023teachersday/",
        photos: [
          "DSC04753.jpg",
          "DSC04760.jpg",
          "380159699_324114710160887_1052329911800748451_n.jpg",
          "380255305_716206837201494_3214950144476592398_n.jpg",
          "DSC04650.jpg",
          "DSC04653.jpg",
          "DSC04664.jpg",
          "DSC04666.jpg",
          "DSC04669.jpg",
          "DSC04693.jpg",
          "DSC04697.jpg",
          "DSC04728.jpg",
          "DSC04743.jpg",
          "DSC04776.jpg",
          "DSC04784.jpg",
          "DSC04790.jpg",
          "DSC04798.jpg",
          "DSC04802.jpg",
          "DSC04812.jpg",
          "DSC04836.jpg",
          "DSC04837.jpg",
          "DSC04840.jpg",
          "DSC04846.jpg",
          "DSC04934.jpg",
          "DSC04951.jpg",
          "DSC04964.jpg",
          "DSC04970.jpg",
          "DSC04971.jpg",
        ],
        moreImagesLink:
          "https://www.facebook.com/SPACmedia/posts/pfbid02HT5F57wfmFN981fn3wikXjHYTSjUwpDHyowfhq7RpCpYjoc7f357s4oc4eWDBgCfl",
        postedBy: {
          img: "/img/spac_media_productions_logo.png",
          name: "SPAC Media",
          link: "https://www.facebook.com/SPACmedia",
        },
        photographers: ["SPAC Media"],
      },
      {
        id: 39,
        title: "National Indigenous Peoples Month Celebration 2023",
        subtitle: `South Philippine Adventist College is celebrating its first Indigenous Peoples Day, where the school recognizes similarities and celebrates differences. 
        Here we value unity in diversity.`,
        date: "Oct 25 2023",
        folder: "/img/events/2023nation_ip_day/",
        photos: [
          "IMG_6463.jpg",
          "DSC_0124.jpg",
          "DSC_0145.jpg",
          "DSC_0149.jpg",
          "DSC_0157.jpg",
          "DSC_0168.jpg",
          "DSC_0175.jpg",
          "DSC_0185.jpg",
          "DSC_0190.jpg",
          "DSC_0198.jpg",
          "DSC05893.jpg",
          "DSC05904.jpg",
          "DSC05908.jpg",
          "DSC05928.jpg",
          "DSC05930.jpg",
          "DSC05932.jpg",
          "DSC05949.jpg",
          "DSC05963.jpg",
          "DSC05965.jpg",
          "DSC05975.jpg",
          "DSC05980.jpg",
          "DSC06018.jpg",
          "DSC06025.jpg",
          "DSC06389.jpg",
          "DSC06391.jpg",
          "DSC06396.jpg",
          "DSC06401.jpg",
          "DSC06409.jpg",
          "DSC06414.jpg",
          "DSC06427.jpg",
        ],
        moreImagesLink:
          "https://www.facebook.com/SPACmedia/posts/pfbid04PVzZNZ4zxPxow6KAvvhTWGiUrDxvZgyMrxovumijN96ZJDHSK79zWR9f5asnGn2l",
        postedBy: {
          img: "/img/spac_media_productions_logo.png",
          name: "SPAC Media",
          link: "https://www.facebook.com/SPACmedia",
        },
        photographers: ["SPAC Media"],
      },
      {
        id: 40,
        title: "First SPAC Research Congress",
        subtitle: `Innovations and Trends: Adapting to a Changing Landscape`,
        date: "Nov 13 2023",
        folder: "/img/events/2023researchcongress/",
        photos: [
          "DJI_0730.jpg",
          "DJI_0726.jpg",
          "DSC06061.jpg",
          "DSC06069.jpg",
          "DSC07282.jpg",
          "DSC07294.jpg",
          "DSC07317.jpg",
          "DSC07327.jpg",
          "DSC07333.jpg",
          "DSC07342.jpg",
          "DSC07364.jpg",
          "DSC07385.jpg",
          "DSC07397.jpg",
          "DSC07401 (2).jpg",
          "DSC07417.jpg",
          "DSC07440.jpg",
          "DSC07448.jpg",
          "DSC07452.jpg",
          "DSC07479.jpg",
          "DSC07495.jpg",
          "DSC07499.jpg",
          "DSC07511.jpg",
          "DSC07515.jpg",
          "DSC07530.jpg",
          "DSC07563.jpg",
          "DSC07565.jpg",
          "DSC07583.jpg",
          "DSC07586.jpg",
          "DSC07589.jpg",
          "DSC07599.jpg",
          "DSC07630.jpg",
          "DSC07700.jpg",
          "DSC07707.jpg",
          "DSC07830.jpg",
          "DSC07870.jpg",
        ],
        moreImagesLink:
          "https://www.facebook.com/SPACmedia/posts/pfbid0xKrCYrN1yKsCA2w7NcG6ydMw5FpjJqpVhpzDtPs3CgHMv8cX8SfnnpmziQbQUfpTl",
        postedBy: {
          img: "/img/spac_media_productions_logo.png",
          name: "SPAC Media",
          link: "https://www.facebook.com/SPACmedia",
        },
        photographers: ["SPAC Media"],
        newsLink: "First SPAC Research Congress",
      },
      {
        id: 41,
        title: "HUGYAW 2023",
        subtitle: `Pakiglambigit sa Hugyaw, Kalipay Ipatigbabaw`,
        date: "Nov 20 2023",
        folder: "/img/events/2023hugyaw/",
        photos: [
          "405475848_731590922341773_5393090838243345999_n.jpg",
          "403739084_730535645780634_3849347825663827671_n.jpg",
          "403745072_730535855780613_4391942903057040082_n.jpg",
          "403772670_731083605725838_4599100207225968399_n.jpg",
          "403783269_731084809059051_6436760691740679613_n.jpg",
          "404231258_730535219114010_813048875509203319_n.jpg",
          "404270035_730535625780636_7127379207273111612_n.jpg",
          "404387127_730535015780697_7348912026696544584_n.jpg",
          "404390686_730535125780686_8015477574693052582_n.jpg",
          "404396787_731091405725058_5746960902855639930_n.jpg",
          "404404389_730535769113955_4047755197996888895_n.jpg",
          "404405142_730535702447295_1048163482370210277_n.jpg",
          "404519817_730535055780693_7258628215077587521_n.jpg",
          "405475832_731590959008436_4261420495765605568_n.jpg",
          "405485180_731590662341799_6807337146506614091_n.jpg",
          "405508118_731592369008295_30955405535645691_n.jpg",
          "405508880_734516038715928_5169642962679256283_n.jpg",
          "405538897_731593629008169_8005488735982339810_n.jpg",
          "405568565_732903152210550_7248326445285449603_n.jpg",
          "405903846_732888095545389_8778510889498551532_n.jpg",
          "405911166_732902135543985_5869027391212981411_n.jpg",
          "405912749_732903502210515_7079861260551910338_n.jpg",
          "405928729_732887795545419_8878895532962371501_n.jpg",
          "405999531_734945325339666_2219339163888603005_n.jpg",
          "406205856_734945682006297_2823921429484626795_n.jpg",
          "406227700_734516272049238_689209268922358950_n.jpg",
        ],
        moreImagesLink:
          "https://www.facebook.com/SPACmedia/posts/pfbid0dNEHvqARdzBghjPiphFb4262CH67kviNWPXLN1Fc4qGSYRZt8q1vEHqfdr19W2f8l",
        postedBy: {
          img: "/img/spac_media_productions_logo.png",
          name: "SPAC Media",
          link: "https://www.facebook.com/SPACmedia",
        },
        photographers: ["SPAC Media"],
      },
      {
        id: 43,
        title: "TINUBDAN 2024",
        subtitle: `Paghanduraw sa Tinubdan kalipay sa kasamtangan`,
        date: "Feb 19 2024",
        alt_date: "Feb 19-21 2024",
        folder: "/img/events/2024/2024_tinubdan/",
        photos: [
          "428286852_784961417004723_5183101339473252914_n.jpg",
          "428292866_784961933671338_9132667294047709388_n.jpg",
          "428297729_784962140337984_7582992313174100852_n.jpg",
          "428330172_784962520337946_4217355546165772273_n.jpg",
          "428344870_784961820338016_8711296680589250169_n.jpg",
          "428432992_784961910338007_7273368958696229638_n.jpg",
          "428509911_785632920270906_3567537058240396101_n.jpg",
          "428604441_785487860285412_7123126950810294401_n.jpg",
          "428605139_785487456952119_7908211976521595033_n.jpg",
          "428607447_785489236951941_4545826733895666668_n.jpg",
          "428608433_785489320285266_3788733431405610628_n.jpg",
          "428610875_786146350219563_8777482425987580801_n.jpg",
          "428611438_785488366952028_6820114523755950012_n.jpg",
          "428613204_785487676952097_2385322626817180126_n.jpg",
          "428615992_786146910219507_9110332555539670186_n.jpg",
          "428616247_785489000285298_149145887378452989_n.jpg",
          "428616726_785633376937527_8108622769168952963_n.jpg",
          "428623147_785489140285284_5922645868962473792_n.jpg",
          "428623724_785489463618585_6343950420425192518_n.jpg",
          "428659389_786113073556224_2002793040727807276_n.jpg",
          "429559583_786112070222991_5758347815636216107_n.jpg",
        ],
        moreImagesLink:
          "https://www.facebook.com/SPACmedia/posts/pfbid034YQQYGSasbEP7YtUtpn1GGXEasHaPBFKH9Jb9FxKNDgndQXmFdYvnSeaGaAoFLJyl",
        postedBy: {
          img: "/img/spac_media_productions_logo.png",
          name: "SPAC Media",
          link: "https://www.facebook.com/SPACmedia",
        },
        photographers: ["SPAC Media"],
      },
      {
        id: 44,
        title: "SPAC Seniors' Presentation and Commissioning Program",
        date: "Dec 1 2023",
        folder: "/img/events/2023/seniors_presentation_2023/",
        photos: [
          "410220229_772766548199327_4836153127245600952_n.jpg",
          "410252103_772768768199105_7147157687743467610_n.jpg",
          "410269942_772768784865770_7415937785440387154_n.jpg",
          "410288853_772767271532588_5171448748525675259_n.jpg",
          "410327535_772768824865766_7311520790407329301_n.jpg",
          "410331125_772768504865798_2535102057970386889_n.jpg",
          "410337991_772766464866002_4558298367084985808_n.jpg",
          "410338240_772768561532459_1471337356814095708_n.jpg",
          "410348733_772768591532456_656426940859133841_n.jpg",
          "410351048_772768314865817_1320705750760012855_n.jpg",
          "410352945_772767854865863_1735279340858615752_n.jpg",
          "410353330_772767951532520_8131848845811214436_n.jpg",
          "410356078_772767381532577_5747653123294022651_n.jpg",
          "410361038_772768738199108_7634435537836808287_n.jpg",
          "410364371_772768931532422_5491435175001228492_n.jpg",
          "410366205_772768114865837_3201552152183621077_n.jpg",
          "410370127_772767601532555_703842845922426859_n.jpg",
          "410380862_772766778199304_1845772199748707409_n.jpg",
          "410391309_772767451532570_8774076452492275561_n.jpg",
          "410403415_772768628199119_7170266452433624051_n.jpg",
          "410449125_772767411532574_717845680465625877_n.jpg",
        ],
        moreImagesLink:
          "https://www.facebook.com/SPAConline/posts/pfbid02no6Vopd19AbvmjwqcRKasmtFgqNdPRBRhP8QGukfqpGMCuz4z1BQX9zouehiXHxml",
        postedBy: {
          img: "/img/spac_media_productions_logo.png",
          name: "SPAC Media",
          link: "https://www.facebook.com/SPACmedia",
        },
        photographers: ["SPAC Media"],
      },
      {
        id: 45,
        title: "SPAC Musical Thanksgiving Concert",
        date: "Dec 16 2023",
        folder: "/img/events/2023/thanksgiving_concert_2023/",
        photos: [
          "412169926_776114214531227_8139352121045528375_n.jpg",
          "412205698_776114894531159_7561370129000949844_n.jpg",
          "412221164_776114511197864_4281253353961167920_n.jpg",
          "412235191_776114744531174_8733297863425255576_n.jpg",
          "412238641_776114917864490_6989148922120492320_n.jpg",
          "412254857_776114617864520_7983394165290488388_n.jpg",
          "412268407_776114697864512_2005072375800959285_n.jpg",
          "412268591_776114714531177_9063970928622800691_n.jpg",
          "412359308_776114684531180_7067300582439275579_n.jpg",
          "412361854_776114117864570_8253587918818839987_n.jpg",
          "412363025_776114567864525_5024298650607835775_n.jpg",
          "412363097_776114581197857_1534793607242458197_n.jpg",
          "412366274_776114077864574_7986021687198591129_n.jpg",
          "412377934_776114851197830_4612695230817992348_n.jpg",
          "412402053_776114467864535_5741313231338836522_n.jpg",
          "412417974_776114921197823_4142812296707463752_n.jpg",
        ],
        moreImagesLink:
          "https://www.facebook.com/SPAConline/posts/pfbid0CXuhmJN3xiJ8LhYXhFNh7qhsgAY7GxnPmx5kfShm139LhbDMbdoU6vqeVi64eQWol",
        postedBy: {
          img: "/img/spac_media_productions_logo.png",
          name: "SPAC Media",
          link: "https://www.facebook.com/SPACmedia",
        },
        photographers: ["SPAC Media"],
      },
      {
        id: 46,
        title: "Simultaneous Earthquake and Fire Drill",
        date: "Nov 29 2023",
        description: `South Philippine Adventist College participated in the Simultaneous Earthquake and Fire Drill spearheaded by the LGU Matanao. 
        The SPAC Community joined as one, taking part in the drills signifying awareness and readiness towards an upcoming calamity. Through all of this, the Community of SPAC will be more aware, educated, and ready for any disaster and risks ahead.`,
        folder: "/img/events/2023/firedrill2023/",
        photos: [
          "399142489_735099518657580_1505530109880909035_n.jpg",
          "399526474_735098898657642_1731847280025443576_n.jpg",
          "406008815_735099231990942_4455582806422279382_n.jpg",
          "406202273_735099611990904_27975929151969929_n.jpg",
          "406206180_735099025324296_4187391271381094298_n.jpg",
          "406208628_735099681990897_6740972699891323482_n.jpg",
          "406220129_735098605324338_3820800536237935823_n.jpg",
          "406222922_735099458657586_622358303459167082_n.jpg",
          "406227972_735099541990911_5551322127317317530_n.jpg",
          "406228098_735099578657574_2670216307395594774_n.jpg",
          "406230840_735099128657619_7086643627657810250_n.jpg",
          "406234883_735099841990881_2725950647317250158_n.jpg",
          "406235955_735099695324229_5540045567878739379_n.jpg",
          "406260664_735099435324255_343679555364607858_n.jpg",
        ],
        moreImagesLink:
          "https://www.facebook.com/SPACmedia/posts/pfbid0283ScJiRQNB9ZgN7wPHuWRgioBDtPnmpgtwMpe2QZ2rMs4XquqUPCpnLvAB9grw9sl",
        postedBy: {
          img: "/img/spac_media_productions_logo.png",
          name: "SPAC Media",
          link: "https://www.facebook.com/SPACmedia",
        },
        photographers: ["SPAC Media"],
      },
      {
        id: 47,
        title: "10 Days of Prayer",
        date: "Jan 10 2024",
        alt_date: "Jan 10-19 2024",
        folder: "/img/events/2024/2024_10daysofprayer/",
        photos: [
          "418730411_791232456352736_2354593689550455270_n.jpg",
          "418731784_791232646352717_4196805617131958972_n.jpg",
          "418733948_791231829686132_734459502966686209_n.jpg",
          "418733957_791232946352687_5541612112606243821_n.jpg",
          "418737234_791232869686028_3105655682641611535_n.jpg",
          "418739187_791890876286894_88996080996617057_n.jpg",
          "418744784_791890522953596_9218424638081054001_n.jpg",
          "418782011_791890476286934_7932848985128718756_n.jpg",
          "418826610_791890659620249_88134932447987578_n.jpg",
          "418835164_792448319564483_229589701797169557_n.jpg",
          "419670840_792448469564468_6397491491140745092_n.jpg",
          "419684909_792448289564486_2289249145782717948_n (1).jpg",
          "419789223_793122492830399_9214155036011332577_n.jpg",
          "419859584_793122369497078_8313425364391865610_n.jpg",
          "419993387_793750129434302_9059925305755415067_n.jpg",
          "420029284_794360602706588_5205951455271647771_n.jpg",
          "420035123_794360936039888_1293955213072239218_n.jpg",
          "420181768_794973932645255_537944830382354959_n.jpg",
        ],
        moreImagesLink:
          "https://www.facebook.com/SPAConline/posts/pfbid0rgwgMPxCHP3FwsttSfHd2WacRnpg4R8zoPs2emRxvLX7ZKQvTkT8jwYfvWJkNR9kl",
        postedBy: {
          img: "/img/spac_media_productions_logo.png",
          name: "SPAC Media",
          link: "https://www.facebook.com/SPACmedia",
        },
        photographers: ["SPAC Media"],
      },
      {
        id: 48,
        title: "Bloodletting Activity ",
        date: "Feb 7 2024",
        folder: "/img/events/2024/2024bloodletting/",
        photos: [
          "426459634_809173844558597_1061678258389858033_n.jpg",
          "425859613_809174107891904_540144160401560420_n.jpg",
          "426411586_809173327891982_6520450854389952277_n.jpg",
          "426412082_809173581225290_4573069482909361778_n.jpg",
          "426442838_809173784558603_3621849005539352728_n.jpg",
          "426599452_809173437891971_7741297851296199480_n.jpg",
          "426619409_809172374558744_8101071652978583924_n.jpg",
          "426621572_809173867891928_6942030041700333733_n.jpg",
          "426627019_809172771225371_3875031263935358912_n.jpg",
          "426627831_809172997892015_3190728575027614559_n.jpg",
          "426628100_809173717891943_7653049737431781056_n.jpg",
          "426635920_809174031225245_1429059796771842621_n.jpg",
          "426636378_809173427891972_7323313821360970986_n.jpg",
          "426648988_809172861225362_7705280208447220254_n.jpg",
          "426686780_809173897891925_8867594460260235121_n.jpg",
          "426701383_809173231225325_7625646564380460874_n.jpg",
          "426830258_809172124558769_8045138045039492909_n.jpg",
        ],
        moreImagesLink:
          "https://www.facebook.com/SPAConline/posts/pfbid0W31zNLGJzfShXq1G2Qgvn1AsxuWJ19R3jiWU1QmBC5SwqZVPTo9HyGDYi9h3vkc2l",
        postedBy: {
          img: "/img/spac_media_productions_logo.png",
          name: "SPAC Media",
          link: "https://www.facebook.com/SPACmedia",
        },
        photographers: ["SPAC Media"],
        newsLink: "Bloodletting Activity",
      },
      {
        id: 49,
        title: "74th Founding Anniversary",
        date: "Feb 22 2024",
        folder: "/img/events/2024/74thfoundinganniversary/",
        photos: [
          "429669008_818769550265693_5473756785801042256_n.jpg",
          "392865638_818769186932396_5491283423857172269_n.jpg",
          "428612525_818769776932337_2500823264424691048_n.jpg",
          "428612989_818768276932487_9103894842083641833_n.jpg",
          "428614288_818769936932321_4139836649983361750_n.jpg",
          "428617270_818769036932411_6547550895460846703_n.jpg",
          "428618822_818768510265797_2263233174580006509_n.jpg",
          "428619206_818767700265878_3118363030220867168_n.jpg",
          "428620700_818769676932347_3204603802392221020_n.jpg",
          "428621107_818770136932301_6562852290437786870_n.jpg",
          "428622935_818767243599257_7606994795938780055_n.jpg",
          "428623565_818769640265684_7022467953711903841_n.jpg",
          "428624406_818769413599040_5743629057386746226_n.jpg",
          "429649971_818769410265707_4645639896258976094_n.jpg",
          "429654196_818769573599024_4283855985382297798_n.jpg",
          "429673520_818770296932285_5088403648997317452_n.jpg",
        ],
        moreImagesLink:
          "https://www.facebook.com/SPAConline/posts/pfbid02kEgN1daPKvzRbX9kjHhKY9ZfeNB8J5FmG7qKSDMo1mk4nYGWRGEsiefK81B6S98dl",
        postedBy: {
          img: "/img/spac_media_productions_logo.png",
          name: "SPAC Media",
          link: "https://www.facebook.com/SPACmedia",
        },
        photographers: ["SPAC Media"],
        newsLink: "SPAC commemorates its 74th founding anniversary",
      },
      {
        id: 50,
        title: "Week of Prayer",
        date: "Feb 26 2024",
        alt_date: "Feb/Mar 26-02 2024",
        subtitle: "Theme: U-Turn Towards the Savior",
        folder: "/img/events/2024/weekofdevotion/",
        photos: [
          "429779920_791656343001897_8605982569802353906_n.jpg",
          "392864247_789304276570437_8348380173921700915_n.jpg",
          "429511076_788769286623936_7445542868259613779_n.jpg",
          "429561541_788769446623920_3156022872529915052_n.jpg",
          "429564524_790453303122201_8435085504902772583_n.jpg",
          "429583457_788769243290607_1803864155369625442_n.jpg",
          "429585523_791252809708917_1658693002938749249_n.jpg",
          "429585721_791253243042207_4175422921727328541_n.jpg",
          "429588302_791253229708875_888642421498954558_n.jpg",
          "429638782_790547443112787_210014070820000579_n.jpg",
          "429648439_789304309903767_8625943322229544442_n.jpg",
          "429649682_791653289668869_489180285116759523_n.jpg",
          "429649695_791741602993371_4887377083425849956_n.jpg",
          "429651338_791742016326663_8273739699560309640_n.jpg",
          "429654697_791653453002186_3263830463320772503_n.jpg",
          "429658483_791656226335242_7381115239742639598_n.jpg",
          "429661699_789846773182854_4541140858145056837_n.jpg",
          "429677644_790547756446089_4530612629426227845_n.jpg",
          "429685070_791653556335509_4401565070353928228_n.jpg",
          "429687193_788990946601770_5750012116852924103_n.jpg",
          "429750369_789847013182830_1850679958032662804_n.jpg",
          "429753836_791741959660002_6907241269434611628_n.jpg",
          "429783270_791741882993343_3315476178023827639_n.jpg",
          "429815575_788992336601631_786670055475617015_n.jpg",
          "429822738_791741826326682_1218220214326024355_n.jpg",
          "429872831_791653863002145_7388729650407812527_n.jpg",
        ],
        moreImagesLink:
          "https://www.facebook.com/SPACmedia/posts/pfbid02PaRfsxdBFrJZaKg3fR3wqZvLJVP7DjP3ucf6qZsneADrv4heazQDnVCLpD43NDbTl",
        postedBy: {
          img: "/img/spac_media_productions_logo.png",
          name: "SPAC Media",
          link: "https://www.facebook.com/SPACmedia",
        },
        photographers: ["SPAC Media"],
      },
      {
        id: 51,
        title: "Elaya Tree Planting",
        date: "Mar 10 2024",
        folder: "/img/events/2024/collegetreeplanting/",
        photos: [
          "430695765_798608525640012_875064536009771977_n.jpg",
          "431097288_798608625640002_5583804893771775330_n.jpg",
          "431899387_798609012306630_898811005494359989_n.jpg",
          "431908578_798609135639951_4800395187837726899_n.jpg",
          "431923732_798608945639970_1429680817784802092_n.jpg",
          "431929798_798608715639993_8447771739048698728_n.jpg",
          "431960045_798608498973348_7642391514002476101_n.jpg",
          "431969171_798609215639943_6563494862492953635_n.jpg",
          "431973298_798608548973343_4021691611584168044_n.jpg",
          "432004183_798609275639937_772319244725066836_n.jpg",
          "432016859_798609575639907_693792458304539348_n.jpg",
          "432057644_798608758973322_3449049727969831012_n.jpg",
          "432061640_798608435640021_8079002787531905965_n.jpg",
          "432647428_798608575640007_5087114921153959561_n.jpg",
          "432647768_798609572306574_3714257924103822167_n.jpg",
          "432657749_798608742306657_3899923597665486967_n.jpg",
          "432750400_798608678973330_4859674828312388750_n.jpg",
        ],
        moreImagesLink:
          "https://www.facebook.com/SPACmedia/posts/pfbid0Lu2BcnY3ctksA6phrKfBbkaAPCHWF5Lr28fpDVmDpWdUwEXDrHCaeShqVe4TjxgCl",
        postedBy: {
          img: "/img/spac_media_productions_logo.png",
          name: "SPAC Media",
          link: "https://www.facebook.com/SPACmedia",
        },
        photographers: ["EIKON"],
        newsLink: "Trees for the Future!",
      },
      {
        id: 52,
        title: "AAA Welcome Program",
        description:
          "On March 14, 2024, SPAC Academy opens its doors to the Adventist Accrediting Association Team of Accreditors led by Dr. Bienvenido Mergal, the Education Director for Southern Asia Pacific Division. Let's take a preview at some of the highlights of the Welcome Program.",
        date: "Mar 14 2024",
        folder: "/img/events/2024/aaaacademyvisit/",
        photos: [
          "431909287_831592722316709_9212830423125253501_n.jpg",
          "431128549_831593215649993_5043357883244520813_n.jpg",
          "431919800_831592142316767_7979160612231185753_n.jpg",
          "431924511_831592028983445_3789773713613715_n.jpg",
          "431941752_831592862316695_1905411469807091575_n.jpg",
          "431964270_831592678983380_2617634077548151462_n.jpg",
          "431977011_831592935650021_4324244543938254803_n.jpg",
          "431985354_831593028983345_5965700880388069309_n.jpg",
          "431993228_831592962316685_1874368745985586627_n.jpg",
          "432013298_831591922316789_9039794772448522778_n.jpg",
          "432032548_831592888983359_2479165098330123650_n.jpg",
          "432032554_831592752316706_4050039795771973331_n.jpg",
          "432042213_831593222316659_2171469717696806598_n.jpg",
          "432750186_831592568983391_4256438494277053232_n.jpg",
        ],
        moreImagesLink:
          "https://www.facebook.com/SPAConline/posts/pfbid0efCb3nbCmQZu4wwNVChvHhG1PsUHw5NEDPPgr1uLeixwbhVoNM7Bfn41TVaAb1DQl",
        postedBy: {
          img: "/img/spac_media_productions_logo.png",
          name: "SPAC Media",
          link: "https://www.facebook.com/SPACmedia",
        },
        photographers: ["SPAC Media"],
      },
      {
        id: 53,
        title: "Pathfinder Tactical inspection",
        date: "Apr 08 2024",
        folder: "/img/events/2024/pathfinder_tactical/",
        photos: [
          "435668447_814636100703921_334324865681142138_n.jpg",
          "435746907_814635234037341_7047388349522168293_n.jpg",
          "423237566_814646667369531_1720097501748123408_n.jpg",
          "425523798_814636964037168_8132883491983370838_n.jpg",
          "435564411_814640254036839_5283704768559028686_n.jpg",
          "435576204_814644400703091_6716879313314655648_n.jpg",
          "435581125_814640080703523_3494534517398954036_n.jpg",
          "435581222_814639867370211_3825513682513959340_n.jpg",
          "435662577_814638794036985_3375186637032373899_n.jpg",
          "435664376_814639097370288_7771906641705333174_n.jpg",
          "435669145_814638604037004_7874167502587402376_n.jpg",
          "435674985_814638400703691_8212133212333383287_n.jpg",
          "435683440_814637664037098_5342786439540517017_n.jpg",
          "435688396_814635547370643_2795916620189506783_n.jpg",
          "435702651_814635337370664_8036178661775261989_n.jpg",
          "435703338_814636774037187_6347110731853494993_n.jpg",
          "435729668_814639914036873_6636871887544000617_n.jpg",
          "435757004_814646264036238_6884072594444979240_n.jpg",
          "435762707_814642694036595_5454051376323550908_n.jpg",
          "435764452_814643787369819_5766567090490576303_n.jpg",
          "435774140_814636154037249_4696221269467369030_n.jpg",
          "435785541_814646447369553_4978546977429781457_n.jpg",
          "435875195_814636634037201_8667486691440978722_n.jpg",
          "435879522_814636230703908_3073324904558747563_n.jpg",
          "435884608_814636800703851_5740733627544710221_n.jpg",
          "436197578_814637174037147_5558656669812464091_n.jpg",
          "436202458_814637107370487_231392959485207523_n.jpg",
          "436210870_814643934036471_9155038542717955706_n.jpg",
          "436222445_814640924036772_266358085780426557_n.jpg",
          "436236812_814636314037233_5912053619330446096_n.jpg",
          "436240329_814636134037251_617131507196777623_n.jpg",
          "436313221_814644044036460_4939923824139999648_n.jpg",
          "436314453_814646567369541_4407118812869671895_n.jpg",
        ],
        moreImagesLink:
          "https://www.facebook.com/SPACmedia/posts/pfbid02nV66hm8yMMKQdKqUzsgHt2MSnhCPoPNJMfUke6CJnan4X9Cpr1SprtBGTN6Ua2PJl",
        postedBy: {
          img: "/img/spac_media_productions_logo.png",
          name: "SPAC Media",
          link: "https://www.facebook.com/SPACmedia",
        },
        photographers: ["SPAC Media"],
        newsLink: "SPACA Pathfinder Tactical Inspection",
      },
    ],
    special_feature: [
      {
        content: `<h1 class="gradient-orange-text">Congratulations</h1>
      <h3>
        to all spac passers in the march 2023 <br />
        licensure examination for <br />
        professional teachers
      </h3>
      <br />
      <br />
      <div class="row">
        <div class="col-12 col-lg-6 mb-4">
          <h5>bachelor of secondary education</h5>
          <h2 class="gradient-orange-text">78.13<small>%</small></h2>
          <h6>first takers</h6>
          <h2 class="gradient-orange-text">69.72<small>%</small></h2>
          <h6>national passing rate</h6>
        </div>
        <div class="col-12 col-lg-6 mb-4">
          <h5>bachelor of elementary education</h5>
          <h2 class="gradient-orange-text">100<small>%</small></h2>
          <h6>first takers</h6>
          <h2 class="gradient-orange-text">78.83<small>%</small></h2>
          <h6>national passing rate</h6>
        </div>
        <img class="tgbtg" src="/img/specialfeature/tgbtg.png" />
      </div>`,
        folder: "/img/specialfeature/let_march_2023/",
        images: [
          "abas.png",
          "ablo.png",
          "ade.png",
          "alburo.png",
          "arellano.png",
          "barretto.png",
          "belisario.png",
          "binarao.png",
          "bualan.png",
          "caliusan.png",
          "cansancio.png",
          "catabijan.png",
          "cordita.png",
          "crisostomo.png",
          "culango.png",
          "dahunan.png",
          "daligdig.png",
          "dobluis.png",
          "ellorin.png",
          "frasco.png",
          "gallo.png",
          "gemoya.png",
          "gonzalo.png",
          "lozano.png",
          "maghinay.png",
          "mataguina.png",
          "moring.png",
          "navales.png",
          "occillada.png",
          "pansacala.png",
          "redoble.png",
          "sedon.png",
          "tagupa.png",
          "taneza.png",
          "zamora.png",
        ],
      },
    ],
    news: [
      {
        id: 1,
        title: "SMA Himamat Grand Alumni Homecoming 2023 Day 1",
        subtitle: "A day to take a trip down memory lane TOGETHER!",
        date: "2023-09-13",
        author: "SPAC Media Productions",
        feature_image:
          "/img/news/day1sma/378317798_234374989604593_4742489717953560491_n.jpg",
        content: `<p>
        The event started with the motorcade from SPAC to Digos City. Then it
        was followed by a trip to Camp Sabros, Kapatagan, and Haven's Dew,
        Balutakay.
      </p>
      <div class="row mb-4">
        <div class="col-12 col-md-6 col-xl-4 p-2">
          <img
            class="md"
            src="/img/news/day1sma/378861046_234375142937911_5553701098042022437_n.jpg"
          />
        </div>
        <div class="col-12 col-md-6 col-xl-4 p-2">
          <img
            class="md"
            src="/img/news/day1sma/378055131_234379416270817_8854421450786342178_n.jpg"
          />
        </div>
        <div class="col-12 col-md-6 col-xl-4 p-2">
          <img
            class="md"
            src="/img/news/day1sma/378278647_234382246270534_6336868131744064546_n.jpg"
          />
        </div>
      </div>

      <p>
        Truly, the First Day of homecoming was a blast, as the alumni drove
        into wonderful and breathtaking scenery along the roads of Davao Del
        Sur.
      </p>

      <div class="row">
        <div class="col-12 col-md-6 col-xl-4 p-2">
          <img
            class="md"
            src="/img/news/day1sma/378707198_234376149604477_3652646245483453320_n.jpg"
          />
        </div>
        <div class="col-12 col-md-6 col-xl-4 p-2">
          <img
            class="md"
            src="/img/news/day1sma/378332558_234375472937878_1762697387004767697_n.jpg"
          />
        </div>
        <div class="col-12 col-md-6 col-xl-4 p-2">
          <img
            class="md"
            src="/img/news/day1sma/378960690_234373179604774_2495525941786384231_n.jpg"
          />
        </div>
      </div>`,
      },
      {
        id: 2,
        title: "SMA Himamat Grand Alumni Homecoming 2023 Day 2",
        subtitle:
          "A Day to Bring Back Memories through Old Songs and Tasty Delicacies",
        date: "2023-09-14",
        author: "SPAC Media Productions",
        feature_image:
          "/img/news/day2sma/376031760_235060572869368_5062617482780578615_n.jpg",
        content: `<p>At exactly 8:00 a.m., Day 2 of the Alumni Homecoming started with a devotional sponsored by the SPAC Academy Faculty. </p>
        <p>After the devotional, the time for the campus tour commenced, during which the alumni were given a chance to tour the SMA-SPAC Campus, showcasing its newly constructed buildings and offices.  </p>
        <div class="row">
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/day2sma/375959058_235058676202891_8983466606939124122_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/day2sma/377933957_235058902869535_6062600219545116675_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/day2sma/378340053_235060506202708_6803587151991095657_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/day2sma/378388035_235060896202669_91382080262100168_n.jpg"
              />
            </div>
          </div>
        <p class="mt-4">Later in the evening, the Alumni were entertained by the different singing groups of SMA-SPAC. Everyone in attendance also shared a hearty feast of Filipino delicacies and fruits, foreshadowing the festive reunion activities in the coming days. </p>
        <p>Indeed, day 2 of the Alumni Homecoming brought a glimpse of the good old days that the alumni came to celebrate and reminisce. </p>
        <div class="row">
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/day2sma/376014882_235059789536113_684952390437126015_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/day2sma/376014942_235060582869367_8398299947524144531_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/day2sma/377877244_235060356202723_1618112179984014194_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/day2sma/378339441_235078066200952_8146458568560019270_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/day2sma/378802884_235061436202615_8745207367824042843_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/day2sma/379108483_235077996200959_621161683629140718_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/day2sma/379237740_235077959534296_5238123516143982447_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/day2sma/379170678_235078249534267_5312682873339336346_n.jpg"
              />
            </div>
          </div>
        `,
      },
      {
        id: 3,
        title: "SMA Himamat Grand Alumni Homecoming 2023 Day 3",
        subtitle:
          "A Day to Rebuild Relationships and Celebrate God's Greatness through Songs and Praises",
        date: "2023-09-15",
        author: "SPAC Media Productions",
        feature_image:
          "/img/news/day3sma/379460015_235690636139695_9005096711178741089_n.jpg",
        content: `<p>Day 3 of the SMA-SPAC Alumni Homecoming opened with a devotional sponsored by the SPAC Faculty.</p>
        <p>The program continued as the Alumni got together to play and be acquainted and reacquainted with fellow alumni and friends through the "SMA Challenge"- a series of thrilling games that brought out the competitive spirit of our alumni. Laughter and cheers echoed through the campus as teams battled it out for glory and for the promised winning prize, a massage package.</p>
        <div class="row">
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/day3sma/379472519_235689149473177_7473559562204252077_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/day3sma/379617723_235691859472906_4561303273277462111_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/day3sma/379517535_235693749472717_2953660006959032897_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/day3sma/379459684_235690276139731_6577635412425141_n.jpg"
              />
            </div>
          
          <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/day3sma/379250093_235691162806309_7908727604645223240_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/day3sma/377963670_235690722806353_4208283538493162432_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/day3sma/379444477_235693916139367_6459163701462213602_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/day3sma/379406705_235690309473061_8206985499385576404_n.jpg"
              />
            </div>
        </div>
        <p class="mt-4">In the evening, the campus came alive with religious melodies and harmonies during the Musical Concert of the different singing and musical groups of SMA-SPAC, with the special participation of the Duyog Chamber Singers. Surely, the beautiful musical renditions inspired the Alumni and the people who came to celebrate and worship with them.</p>
        <p>God be praised for the success of the 3rd Day of the Alumni Homecoming.</p>
        <div class="row">
            
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/day3sma/377947410_235689132806512_187880525192251297_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/day3sma/379403745_235692092806216_7779483929976184713_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/day3sma/379511363_235695562805869_2166111108164262162_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/day3sma/379463687_235692556139503_914077605866187911_n.jpg"
              />
            </div>
          </div>
        `,
      },
      {
        id: 4,
        title: "SMA Himamat Grand Alumni Homecoming 2023 Day 4",
        subtitle:
          "A Special Sabbath of Praises and Thanksgiving for God's Goodness to SMA-SPAC",
        date: "2023-09-16",
        author: "SPAC Media Productions",
        feature_image:
          "/img/news/day4sma/378797493_236994122676013_3871117585455459585_n.jpg",
        content: `<p>The presence of the SMA-SPAC alumni from different batches made the SPAC Church Sabbath Services extraordinary! A Sabbath day filled with happiness as the alumni reunited in one place to worship and praise god for His constant providence to SMA-SPAC throughout the years. </p>
        <p>With the presence of Dr. Don Leo Garilva, the former president of MVC, and his team, the Sabbath School was engaging and active as they organized an interactive discussion of the lesson. </p>
        <p>During the Hour of Worship, Ptr. Danielo Palomares, the SPUC president, offered a timely and relevant message that left a lasting impact on the alumni and the congregation. </p>
        <div class="row">
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/day4sma/378265559_236993879342704_3194023173218750117_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/day4sma/378947705_236994019342690_8074336338426894369_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/day4sma/378807972_236993302676095_4225977651100574116_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/day4sma/379182006_236994239342668_5839861475703285437_n.jpg"
              />
            </div>
          
          <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/day4sma/379257502_236995089342583_6368325034970229188_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/day4sma/379231751_236993856009373_3976188047716429984_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/day4sma/378386653_236993916009367_2268205206768973901_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/day4sma/377272269_236993782676047_5008061728638485863_n (1).jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/day4sma/377447430_236994736009285_7771167963514280055_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/day4sma/379253722_236993629342729_8747628666003042134_n.jpg"
              />
            </div>
        </div>
        <p class="mt-4">The service continued until the afternoon as the alumni walked down the aisle for their batch parade, which revealed the faces of the people who established a legacy in Southern Mindanao Academy, now SPAC. </p>
        <p>It was then followed by the election of the alumni officers headed by the organization's newly elected president, Dr. Mikael Oliverio Allarde.</p>
        <p>The night shimmered with elegance and nostalgia as the alumni, SPAC administrators, faculty, and staff, gathered for the Grand Alumni Ball at the Hotel de Crisbelle, Digos City dressed in their formal wear, and forged memories that will forever hold a special place in their hearts.</p>
        <p>It was a grand and blessed Sabbath indeed! </p>
        <div class="row">
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/day4sma/380004945_237004232675002_4108393716224360061_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/day4sma/380048353_237007666007992_1096170671346372132_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/day4sma/380143709_237005752674850_7632661555266376404_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/day4sma/380432894_237004936008265_5209110168470930250_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/day4sma/380433897_237006682674757_9058781839637966767_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/day4sma/379855320_237010129341079_4013565352822139819_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/day4sma/380202869_237010622674363_1628112662260848979_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/day4sma/380438201_237006169341475_2232486195192694976_n.jpg"
              />
            </div>
          </div>
        `,
      },
      {
        id: 5,
        title: "SPAC Hosts Journalism Workshop for Media Enthusiasts",
        date: "2023-09-24",
        author: "SPAC Media Productions",
        feature_image:
          "/img/news/2023journalismworkshop/381009309_726553192820663_2742381267242597010_n (2).jpg",
        content: `<p>On September 24, 2023, a gathering of 49 staff members hailing from various publications, alongside dedicated media volunteers from the Academy and College, converged at the SPAC College Library. Their purpose is to take part in the Journalism Workshop thoughtfully organized by the Academy Publications Committee at South Philippine Adventist College.</p>
        <p>This enriching event encompassed a range of activities, blending hands-on training in photography and writing. The workshop featured esteemed lecturers, including Mr. Jay Genon, the Media Equipment In-charge at SPAC Media Productions, and Dr. Gemini Asok, a renowned researcher and retired professor from Mountain View College.</p>

        <div class="row">
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/events/2023journalismworkshop/DSC05298.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/events/2023journalismworkshop/DSC04574.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/events/2023journalismworkshop/DSC04613.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/events/2023journalismworkshop/DSC04583.jpg"
              />
            </div>
        </div>
        <p class="mt-4">As the day unfolded, participants eagerly absorbed valuable insights and practical skills. The highlight of the gathering was the culmination of their efforts—a presentation of their creative outputs. Each publication group had the opportunity to showcase their talents and innovation in both photography and journalism, leaving a lasting impression on all in attendance." </p>
        <div class="row">
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/events/2023journalismworkshop/DSC04599.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/events/2023journalismworkshop/DSC04607.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/events/2023journalismworkshop/DSC05303.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/events/2023journalismworkshop/DSC04598.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/events/2023journalismworkshop/DSC04563.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/events/2023journalismworkshop/DSC05331.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/events/2023journalismworkshop/DSC05293.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/events/2023journalismworkshop/DSC05323.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/events/2023journalismworkshop/DSC05281.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/events/2023journalismworkshop/DSC05415.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/events/2023journalismworkshop/DSC05416.jpg"
              />
            </div>
          </div>
        `,
      },
      {
        id: 6,
        title: "World Teachers' Day Celebration",
        date: "2023-10-03",
        author: "SPAC Media",
        feature_image:
          "/img/news/2023worldteachersday/370164913_703039591863573_3290425307530140806_n.jpg",
        content: `<p>"Empowering Educators: Strengthening Resilience, Building Sustainability" is the theme for this year's World Teachers' Day Celebration.</p>
        <p>Thanks to the initiative of the CSG, SSC, JASC, and the Faculty Association, the Teachers' Day Celebration of South Philippine Adventist College became a reality.</p>
        <div class="row">
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2023worldteachersday/385319323_703056918528507_1545058163119863454_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2023worldteachersday/386758829_703039478530251_2526876720799764933_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2023worldteachersday/370148668_703056758528523_8609578019403728777_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2023worldteachersday/386350523_703038901863642_8557113845442023078_n.jpg"
              />
            </div>
        </div>
        <p>The program started with a devotional and was graced by the message of the CSG President, Ms. Donabell Espinosa. The Teaching and Non-Teaching staff were entertained by the Elementary, Academy, and College Students with songs and various presentations.</p>
        <p>The Teachers' Day celebration was a blast. Together, let us continue to appreciate and thank those people behind our success.</p>
        <p>HAPPY WORLD TEACHERS' DAY!</p>
        <div class="row">
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2023worldteachersday/385315591_703039658530233_1375530669518660891_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2023worldteachersday/386333802_703039368530262_6748195696993078265_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2023worldteachersday/386334057_703056558528543_6711948974967664832_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2023worldteachersday/386765462_703039415196924_1470622407260820345_n.jpg"
              />
            </div>
            
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2023worldteachersday/386740778_703341455166720_4728896579158487804_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2023worldteachersday/387023462_703341501833382_3910166026377573652_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2023worldteachersday/387084464_703341575166708_8805624570527404877_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2023worldteachersday/387101267_703341541833378_386261990639598312_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2023worldteachersday/386338027_703058971861635_6775698557944659736_n.jpg"
              />
            </div>
          
          </div>
        `,
      },
      {
        id: 7,
        title: "First SPAC Research Congress",
        date: "2023-11-13",
        author: "SPAC Media",
        subtitle: "Innovations and Trends: Adapting to a Changing Landscape",
        feature_image:
          "/img/news/2023researchcongress/401506234_726476226186576_2600200344838012449_n.jpg",
        content: `<p>Share the knowledge to lead the innovations of tomorrow. The first SPAC Research Congress, held on November 13, 2023, set the stage for a future of excellence in research.</p>
        <p>With the theme "Innovations and Trends: Adapting to a Changing Landscape", SPAC is honored to have the brilliant Dr. Adrian V. Protacio of Sultan Kudarat State University as the distinguished keynote speaker during the research congress. His presentations and the experiences he generously shared sparked inspiration in the minds of students and teachers, encouraging them to embrace research innovations and share their newfound knowledge in the field.</p>
        <div class="row">
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2023researchcongress/399650410_726474532853412_2608044499285618641_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2023researchcongress/400415073_726474292853436_5130584793735382887_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2023researchcongress/400456856_726473886186810_1730291470339642616_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2023researchcongress/400569116_726473692853496_2876447798831598463_n.jpg"
              />
            </div>
        </div>
        <p>The day was filled with passion as students, teachers, and alumni presented their research studies across various fields. From morning to afternoon, attendees engaged in intellectually stimulating discussions during parallel sessions, witnessing the presentation of 66 research studies. </p>
        <p>Praise God for this remarkable day dedicated to learning, innovation, and knowledge sharing! SPAC will continue to work on rearing students and faculty toward excellence in research.</p>
        <div class="row">
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2023researchcongress/399610428_726472879520244_5987234413859380545_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2023researchcongress/401472375_726472692853596_4866877381458574529_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2023researchcongress/401486317_726472569520275_4749993637926774118_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2023researchcongress/401493962_726472722853593_3920877216350085405_n.jpg"
              />
            </div>
            
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2023researchcongress/401497214_726473019520230_1252525684336059550_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2023researchcongress/401503225_726472912853574_2821744578659293995_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2023researchcongress/401506808_726472856186913_3342789981445874593_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2023researchcongress/401526423_726472396186959_258958433073461323_n.jpg"
              />
            </div>
          </div>
        `,
      },
      {
        id: 8,
        title: "Bloodletting Activity",
        date: "2024-02-07",
        author: "SPAC Media",
        feature_image:
          "/img/news/2024/2024bloodletting/426701269_809171994558782_1542397591827924845_n.jpg",
        content: `<p>South Philippine Adventist College joined forces with Southern Philippines Medical Center to organize a highly successful bloodletting activity on February 7, 2024, at the SPAC Gymnasium.</p>
        <p>The event drew participation from over 150 students, faculty, staff, and residents from nearby areas. Their collective generosity resulted in collecting an impressive total of 122 bags of blood, surpassing initial expectations, and highlighting the importance of such initiatives in addressing critical healthcare needs.</p>
            <div class="row">
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2024/2024bloodletting/426648988_809172861225362_7705280208447220254_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2024/2024bloodletting/426442838_809173784558603_3621849005539352728_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2024/2024bloodletting/426666427_809173977891917_2438289028665168289_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2024/2024bloodletting/426411586_809173327891982_6520450854389952277_n.jpg"
              />
            </div>
        </div>
        <p>The collaboration between South Philippine Adventist College and Southern Philippines Medical Center exemplifies the power of partnerships in mobilizing resources and expertise to serve the community. Through their combined efforts, they were able to streamline the donation process and ensure the smooth operation of the event.</p>
        <p>Praise God for this remarkable day dedicated to learning, innovation, and knowledge sharing! SPAC will continue to work on rearing students and faculty toward excellence in research.SPAC and SPMC expressed gratitude to all participants for their support and emphasized the life-saving potential of blood donations in emergency situations.</p>
        <div class="row">
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2024/2024bloodletting/426628100_809173717891943_7653049737431781056_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2024/2024bloodletting/426660730_809173701225278_6480861929652773842_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2024/2024bloodletting/426627019_809172771225371_3875031263935358912_n.jpg"
              />
            </div>
          
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2024/2024bloodletting/426627831_809172997892015_3190728575027614559_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2024/2024bloodletting/426605745_809172631225385_1486631877881154641_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2024/2024bloodletting/425861148_809172251225423_4620515276110384429_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2024/2024bloodletting/426701383_809173231225325_7625646564380460874_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2024/2024bloodletting/426830258_809172124558769_8045138045039492909_n.jpg"
              />
            </div>
          </div>
          <p>The bloodletting activity is a testament to the proactive approach taken by South Philippine Adventist College and Southern Philippine Medical Center in addressing healthcare challenges and fostering a culture of solidarity and service within the community.</p>
        `,
      },
      {
        id: 8,
        title: "Trees for the Future!",
        subtitle: "Elaya Tree Planting",
        date: "2024-03-10",
        author: "EIKON",
        feature_image:
          "/img/news/2024/collegetreeplanting/431913910_798608315640033_1874055252785456539_n.jpg",
        content: `<p>On March 10, 2024, the Elaya Senior Class of the College Department conducted a Tree Planting Activity at South Philippine Adventist College. The Elaya Officers, headed by Queenie Emily Paño, led the tree planting along with the guidance of the different chairpersons of every department.</p>
            <div class="row">
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2024/collegetreeplanting/431097288_798608625640002_5583804893771775330_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2024/collegetreeplanting/430695765_798608525640012_875064536009771977_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2024/collegetreeplanting/431899387_798609012306630_898811005494359989_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2024/collegetreeplanting/431908578_798609135639951_4800395187837726899_n.jpg"
              />
            </div>
        </div>
        <p>Graduating students from the AB-Theology department, Teacher Education department, Business Administration department, Accountancy department, Computer Science Department, and Office Administration department gathered together with a mission of planting trees for tomorrow. With this purpose, we took a step towards making this Earth a better place to live in. </p>
        <p>"He who plants a tree, plants a hope." - Lucy Larcom</p>
        <div class="row">
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2024/collegetreeplanting/431923732_798608945639970_1429680817784802092_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2024/collegetreeplanting/431960045_798608498973348_7642391514002476101_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2024/collegetreeplanting/431969171_798609215639943_6563494862492953635_n.jpg"
              />
            </div>
          
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2024/collegetreeplanting/431973298_798608548973343_4021691611584168044_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2024/collegetreeplanting/432057644_798608758973322_3449049727969831012_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2024/collegetreeplanting/432061640_798608435640021_8079002787531905965_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2024/collegetreeplanting/432647428_798608575640007_5087114921153959561_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2024/collegetreeplanting/432630674_798608772306654_5503126635314575396_n.jpg"
              />
            </div>
          </div>
        `,
      },
      {
        id: 9,
        title: "SPAC commemorates its 74th founding anniversary",
        date: "2024-02-23",
        author: "SPAC Media",
        feature_image:
          "/img/news/2024/74foundinganniversary/428616773_818767110265937_5831678633842351373_n.jpg",
        content: `<p>Today marks a momentous occasion for South Philippine Adventist College (SPAC), formerly Southern Mindanao Academy (SMA) as it commemorates its 74th founding anniversary. </p>
            <div class="row">
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2024/74foundinganniversary/428615055_818770370265611_7376709610075411554_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2024/74foundinganniversary/428615859_818769223599059_6479161242492825271_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2024/74foundinganniversary/428619206_818767700265878_3118363030220867168_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2024/74foundinganniversary/428619238_818767920265856_9084827912877075306_n.jpg"
              />
            </div>
        </div>
        <p>Reflecting on its illustrious history, SPAC remains steadfast in its dedication to nurturing future leaders imbued with compassion, integrity, and a sense of purpose. As the students look ahead to the future, embracing the challenges and opportunities that lie ahead, guided by their unwavering faith and commitment to excellence. Here's to 74 years of shaping minds and touching hearts at South Philippine Adventist College!</p>
        <div class="row">
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2024/74foundinganniversary/428620758_818768420265806_3967758210887778300_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2024/74foundinganniversary/428619740_818769780265670_3841948705543934905_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2024/74foundinganniversary/428620852_818768806932434_6769143272564992449_n.jpg"
              />
            </div>
          
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2024/74foundinganniversary/428620700_818769676932347_3204603802392221020_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2024/74foundinganniversary/429673520_818770296932285_5088403648997317452_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2024/74foundinganniversary/428612525_818769776932337_2500823264424691048_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2024/74foundinganniversary/428615859_818769223599059_6479161242492825271_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2024/74foundinganniversary/429669008_818769550265693_5473756785801042256_n (1).jpg"
              />
            </div>
          </div>
        `,
      },
      {
        id: 10,
        title: "SPACA Pathfinder Tactical Inspection",
        date: "2024-04-08",
        author: "SPAC Media",
        feature_image:
          "/img/news/2024/pathfinder_tactical/435569639_814630754037789_8014331344625464800_n.jpg",
        content: `<p>On, April 8, 2024, during the Pathfinder Tactical inspection, the club headed by Pathfinder Club Director Jandphil Jazz Palomares showcased unparalleled dedication and excellence, earning an outstanding overall average rating of 99.08%!</p>
            <div class="row">
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2024/pathfinder_tactical/435664376_814639097370288_7771906641705333174_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2024/pathfinder_tactical/435668439_814636460703885_3289462698353302617_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2024/pathfinder_tactical/435668447_814636100703921_334324865681142138_n.jpg"
              />
            </div>
            <div class="col-6 col-xl-3">
              <img
                class="md"
                src="/img/news/2024/pathfinder_tactical/435774140_814636154037249_4696221269467369030_n.jpg"
              />
            </div>
        </div>
        <p>But the celebrations do not end there! We are overjoyed to highlight the baptism of four (4) Pathfinders at the conclusion of today's inspection, proof of the club's active involvement in winning precious souls for Christ.</p>
        <div class="row">
          <div class="col-6 col-xl-4">
            <img
              class="md"
              src="/img/news/2024/pathfinder_tactical/435764452_814643787369819_5766567090490576303_n.jpg"
            />
          </div>
          <div class="col-6 col-xl-4">
            <img
              class="md"
              src="/img/news/2024/pathfinder_tactical/436301087_814643884036476_1643785841829601792_n.jpg"
            />
          </div>
          <div class="col-6 col-xl-4">
            <img
              class="md"
              src="/img/news/2024/pathfinder_tactical/436210870_814643934036471_9155038542717955706_n.jpg"
            />
          </div>
        </div>
        <p>Congratulations to every member of the SPAC Pathfinder Club for your exceptional performance and commitment to excellence. Your hard work, teamwork, and dedication have indeed paid off, reflecting the values of South Philippine Adventist College.</p>
        <p>Together, let us continue to strive for excellence and make SPAC proud! </p>
        <p>TO GOD BE THE GLORY!</p>
        <div class="row">
          <div class="col-6 col-xl-3">
            <img
              class="md"
              src="/img/news/2024/pathfinder_tactical/436236812_814636314037233_5912053619330446096_n.jpg"
            />
          </div>
          <div class="col-6 col-xl-3">
            <img
              class="md"
              src="/img/news/2024/pathfinder_tactical/435581125_814640080703523_3494534517398954036_n.jpg"
            />
          </div>
          <div class="col-6 col-xl-3">
            <img
              class="md"
              src="/img/news/2024/pathfinder_tactical/435785541_814646447369553_4978546977429781457_n.jpg"
            />
          </div>
          <div class="col-6 col-xl-3">
            <img
              class="md"
              src="/img/news/2024/pathfinder_tactical/435889056_814635714037293_5744496996088077795_n.jpg"
            />
          </div>
          <div class="col-6 col-xl-3">
            <img
              class="md"
              src="/img/news/2024/pathfinder_tactical/435885047_814645530702978_4230894366192640050_n.jpg"
            />
          </div>
          <div class="col-6 col-xl-3">
            <img
              class="md"
              src="/img/news/2024/pathfinder_tactical/436222445_814640924036772_266358085780426557_n.jpg"
            />
          </div>
          <div class="col-6 col-xl-3">
            <img
              class="md"
              src="/img/news/2024/pathfinder_tactical/436229701_814646390702892_7443954379123637375_n.jpg"
            />
          </div>
          <div class="col-6 col-xl-3">
            <img
              class="md"
              src="/img/news/2024/pathfinder_tactical/435725105_814635664037298_5981750494467938928_n.jpg"
            />
          </div>
        </div>
        `,
      },
    ],
  },
  mutations: {},
  getters: {
    sortedEvents(state) {
      const data = JSON.parse(JSON.stringify(state.events));
      return data.sort(function (a, b) {
        return new Date(b.date) - new Date(a.date);
      });
    },
    sortedNews(state) {
      const data = JSON.parse(JSON.stringify(state.news));
      return data.sort(function (a, b) {
        return new Date(b.date) - new Date(a.date);
      });
    },
    latestEvents(state, getters) {
      return getters.sortedEvents.slice(0, 4);
    },

    latestNews(state, getters) {
      return getters.sortedNews.slice(0, 4);
    },
  },
  actions: {},
  modules: {},
});
